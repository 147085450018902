import IBroadPhaseResult from "./IBroadPhaseResult";
import PhysicsBody from "./PhysicsBody";

export default function BroadPhase(sceneEntities: Array<PhysicsBody>): IBroadPhaseResult {
  let result = {
    pairs: [],
  };

  // let worldBoundsA = new Box3();
  // let worldBoundsB = new Box3();

  for (let i = 0; i < sceneEntities.length; i++) {
    let bodyA = sceneEntities[i];

    for (let j = i + 1; j < sceneEntities.length; j++) {
      let bodyB = sceneEntities[j];

      // worldBoundsA.copy(bodyA.bounds).applyMatrix4(bodyA.matrixWorld);
      // worldBoundsB.copy(bodyB.bounds).applyMatrix4(bodyB.matrixWorld);

      if (bodyA.worldBounds.intersectsBox(bodyB.worldBounds)) {
        result.pairs.push({
          bodyA,
          bodyB,
        });
      }
    }
  }

  return result;
}
