import { Color, FrontSide, MeshStandardMaterial, Shader, Texture, Vector3 } from "three";
import fragShader from "../../Assets/shaders/TerrainFragmentShader.glsl";
import vertexShader from "../../Assets/shaders/TerrainVertexShader.glsl";

export interface ITerrainMaterialParameters {
  color: Color;
  heightMap: Texture;
  heightScale: number;
  playerPos: Vector3;
  mapSize: number;
  viewSize: number;
  subdivisions: number;
  textureSize: number;
  // fog: FogExp2;
}

export default class TerrainMaterial extends MeshStandardMaterial {
  constructor(props: ITerrainMaterialParameters) {
    let baseShaderParams = {
      color: props.color,
      flatShading: true,
      roughness: 0.9,
      metalness: 0,
      emissive: new Color(0xaaaacc),
      emissiveIntensity: 0.1,
      side: FrontSide
    };

    super(baseShaderParams);

    // this.setValues(baseShaderParams);


    this.onBeforeCompile = (shader: Shader) => {
      shader.vertexShader = vertexShader;
      shader.fragmentShader = fragShader;

      shader.uniforms.heightMap = { value: props.heightMap };
      shader.uniforms.heightScale = { value: props.heightScale };
      shader.uniforms.playerPos = { value: props.playerPos };
      shader.uniforms.mapSize = { value: props.mapSize };
      shader.uniforms.viewSize = { value: props.viewSize };
      shader.uniforms.subdivisions = { value: props.subdivisions };
      shader.uniforms.textureSize = { value: props.textureSize };
    }

    this.skinning = false;
    this.morphTargets = false;
    this.morphNormals = false;
  }
}
