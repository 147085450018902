import { BoxGeometry, Group, Mesh, MeshBasicMaterial, MeshStandardMaterial, Quaternion, Scene, SphereGeometry, Vector3 } from "three";
import BoxCollider from "./Physics/Colliders/BoxCollider";
import ICollider from "./Physics/Colliders/ICollider";
import SphereCollider from "./Physics/Colliders/SphereCollider";
import PhysicsBody from "./Physics/PhysicsBody";
import WayPoint from "./WayPoint";

export default class Debug {
  // #region Properties (4)

  private _showDebugMeshes: boolean;

  public debugMeshes: (Mesh | Group)[];
  public scene: Scene;
  public watchers: any[];

  // #endregion Properties (4)

  // #region Constructors (1)

  constructor(scene: Scene) {
    this.watchers = Array();
    this.scene = scene;
    this.debugMeshes = [];
    this.showDebugMeshes = true;
  }

  // #endregion Constructors (1)

  // #region Public Accessors (2)

  public get showDebugMeshes(): boolean {
    return this._showDebugMeshes;
  }

  public set showDebugMeshes(val: boolean) {
    if (val && !this._showDebugMeshes) {
      this.debugMeshes.forEach((x) => {
        this.scene.add(x);
      });
    } else if (!val && this._showDebugMeshes) {
      this.debugMeshes.forEach((x) => {
        this.scene.remove(x);
      });
    }

    this._showDebugMeshes = val;
  }

  // #endregion Public Accessors (2)

  // #region Public Methods (4)

  public addDebugWayPoint(waypoint: WayPoint) {
    let sphereMesh = new Mesh(
      new SphereGeometry(waypoint.radius, 8, 8),
      new MeshBasicMaterial({
        color: 0xf5d035,
        transparent: true,
        opacity: 0.5,
      })
    );

    sphereMesh.name = "debug_waypoint";
    sphereMesh.userData = { parent: waypoint };
    sphereMesh.position.set(waypoint.position.x, waypoint.position.y, waypoint.position.z);

    this.debugMeshes.push(sphereMesh);
    this.scene.add(sphereMesh);
  }

  public addDebugPhysicsMesh(physicsBody: PhysicsBody) {
    // Debug mesh for broadphase bounds
    let boundsSize = new Vector3();
    let boundsCenter = new Vector3();
    physicsBody.worldBounds.getSize(boundsSize);
    physicsBody.worldBounds.getCenter(boundsCenter);

    let boundsMesh = new Mesh(
      new BoxGeometry(1, 1, 1),
      new MeshBasicMaterial({
        wireframe: true,
        color: 0xff7500,
      })
    );
    let p = boundsCenter;
    boundsMesh.position.set(p.x, p.y, p.z);
    boundsMesh.scale.set(boundsSize.x, boundsSize.y, boundsSize.z);
    boundsMesh.userData = { physicsBody };
    boundsMesh.name = "debug_bounds";
    this.debugMeshes.push(boundsMesh);
    this.scene.add(boundsMesh);

    // Debug meshes for colliders...
    let colliderMeshes = physicsBody.colliders.map((x) => {
      let mesh: Mesh;
      if (x instanceof SphereCollider) {
        let collider = x as SphereCollider;
        mesh = new Mesh(
          new SphereGeometry(collider.radius, 8, 8),
          new MeshStandardMaterial({
            wireframe: false,
            flatShading: true,
            color: 0xf5d035,
            transparent: true,
            opacity: 0.5,
          })
        );
      } else if (x instanceof BoxCollider) {
        let collider = x as BoxCollider;
        mesh = new Mesh(
          new BoxGeometry(collider.size.x, collider.size.y, collider.size.z),
          new MeshStandardMaterial({
            wireframe: false,
            flatShading: true,
            color: 0xf5d035,
            transparent: true,
            opacity: 0.5,
          })
        );
      }

      if (mesh) {
        let p = x.position;
        mesh.position.set(p.x, p.y, p.z);
        // mesh.setRotationFromQuaternion(x.orientation);
        mesh.userData = { collider: x, physicsBody };
        mesh.name = "debug_collider";
        return mesh;
      }
    });

    if (colliderMeshes.length > 0) {
      let g = new Group();
      g.name = "debug_body";
      g.userData = { physicsBody: physicsBody };
      p = physicsBody.position;
      let s = physicsBody.scale;

      g.position.set(p.x, p.y, p.z);
      g.setRotationFromQuaternion(physicsBody.orientation);
      g.scale.set(s.x, s.y, s.z);
      g.add(...colliderMeshes);
      this.scene.add(g);
      this.debugMeshes.push(g);
    }
  }

  public addWatch(name: string, obj: any) {
    let idx = this.watchers.findIndex((x) => x.name === name);
    if (idx < 0) {
      this.watchers.push({
        name: name,
        val: obj,
      });
    } else {
      this.watchers[idx].val = obj;
    }
  }

  public removeWatch(name: string) {
    let idx = this.watchers.findIndex((x) => x.name === name);
    if (idx >= 0) {
      this.watchers = this.watchers.slice(0, idx).concat(this.watchers.slice(idx + 1));
    }
  }

  public update() {
    let content = "";
    for (let i = 0; i < this.watchers.length; i++) {
      let watch = this.watchers[i];
      let v = JSON.stringify(watch.val);
      content += `<p>${watch.name}: ${v}</p>`;
    }

    for (let i = 0; i < this.debugMeshes.length; i++) {
      let mesh = this.debugMeshes[i];
      let physicsBody: PhysicsBody = mesh.userData.physicsBody;
      let t = new Vector3();
      let r = new Quaternion();
      let s = new Vector3();

      switch (mesh.name) {
        case "debug_bounds":
          physicsBody.worldBounds.getCenter(t);
          physicsBody.worldBounds.getSize(s);
          break;

        case "debug_body":
          physicsBody.matrixWorld.decompose(t, r, s);
          mesh.setRotationFromQuaternion(r);

          mesh.children.forEach((x) => {
            let collider = x.userData.collider as ICollider;
            x.position.copy(collider.position);
            x.setRotationFromQuaternion(collider.orientation);
          });
          break;

        case "debug_waypoint":
          let parent = mesh.userData.parent as WayPoint;
          t.copy(parent.position);
          s.set(1, 1, 1);
      }

      mesh.position.copy(t);
      mesh.scale.copy(s);
    }

    document.getElementById("debug").innerHTML = content;
  }

  // #endregion Public Methods (4)
}

export const debug = () => (window as any).debug as Debug;
