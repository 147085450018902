export default [
  {
    position: {
      x: 9815.958250487854,
      y: 265,
      z: -15383.947489281616,
    },
    radius: 60,
    direction: { x: 0, y: -1, z: 0 },
    userData: {
      opacity: 0,
    },
  },
  {
    position: {
      x: 9459,
      y: 265,
      z: -14668,
    },
    radius: 60,
    direction: { x: 0, y: -0.9, z: 0 },
    userData: {
      opacity: 0,
    },
  },
  {
    position: {
      x: 8741.205471517902,
      y: 300.5757125457283,
      z: -13231.632053845618,
    },
    radius: 60,
    direction: { x: 0, y: 0.3, z: 0 },
    userData: {
      opacity: 2,
    },
  },
  {
    position: {
      x: 8166.964215569304,
      y: 762.1956286419832,
      z: -12079.882276731794,
    },
    radius: 60,
    direction: { x: 0, y: -0.3, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 7792.31607262486,
      y: 871.1686274509804,
      z: -11328.454352209783,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 7512.763480626733,
      y: 865.3367167063174,
      z: -10767.758595522011,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 7267.275077775943,
      y: 851.8569834599729,
      z: -10275.38503242725,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 7018.352803636967,
      y: 867.1217721649482,
      z: -9776.124189030284,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 6754.943213122749,
      y: 693.69112374123,
      z: -9247.806284279211,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 6491.696743065243,
      y: 777.7278100242178,
      z: -8719.815548549366,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: { x: 5948.483315243447, y: 845.7303106505058, z: -7631.117284968416 },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 5741.994580031465,
      y: 763.1753156222709,
      z: -7216.1456358930249,
    },
    radius: 60,
    direction: { x: 0, y: -0.7, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 5406.871301109407,
      y: 689.6090483577349,
      z: -6543.992324361065,
    },
    radius: 60,
    direction: { x: 0, y: -0.2, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 5104.116492165671,
      y: 822.9803921568628,
      z: -5936.760119936702,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 4856.408164161809,
      y: 800.9965588484539,
      z: -5439.934075809348,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 4601.357418134091,
      y: 776.4593146643849,
      z: -4928.381419417782,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 4354.008893133903,
      y: 628.0894784230629,
      z: -4432.277028471861,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 4085.290349589915,
      y: 595.4036771727275,
      z: -3893.3110112673296,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 3843.5489424788067,
      y: 708.1932865237518,
      z: -3408.4527589645397,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: { x: 3455.9970459634405, y: 752.5886006224575, z: -2631.143908707422 },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 3292.9956537580198,
      y: 749.1363422335792,
      z: -2304.2136939291228,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 3037.707805586427,
      y: 717.3288515155552,
      z: -1792.185484207782,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 2694.4858208372518,
      y: 866.5768334650358,
      z: -1103.7886817481349,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 1,
    },
  },
  {
    position: {
      x: 2432.1936251049439,
      y: 1016.9585950111084,
      z: -577.7119242111951,
    },
    radius: 60,
    direction: { x: 0, y: -0.1, z: 0 },
    userData: {
      opacity: 0,
    },
  },
];
