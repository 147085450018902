import { MathUtils, Vector3 } from "three";
import DEFAULT_SPEEDS from "./DEFAULT_SPEEDS";
import PhysicsBody from "./Physics/PhysicsBody";
import Velocity from "./Physics/Velocity";

export default class PlayerState {
  // #region Properties (6)

  public maxPitch: number;
  public maxSpeed: number;
  public minPitch: number;
  public name: string;
  public resolveMove: Function;
  public targetSpeed: Function;

  // #endregion Properties (6)

  // #region Constructors (1)

  constructor(args: {
    name: string;
    maxSpeed: number;
    minPitch: number;
    maxPitch: number;
    targetSpeed: Function;
    resolveMove: Function;
  }) {
    if (args) {
      this.name = args.name;
      this.maxSpeed = args.maxSpeed;
      this.minPitch = args.minPitch;
      this.maxPitch = args.maxPitch;
      this.targetSpeed = args.targetSpeed.bind(this);
      this.resolveMove = args.resolveMove;
    }
  }

  // #endregion Constructors (1)
}

const _doNothing = function () {};
const _resolveMove = function (
  physicsBody: PhysicsBody,
  direction: Vector3,
  targetSpeed: number
) {
  // Pass the speed as a function, to be resolved when the PhysicsManager resolves all movements during its update.
  physicsBody.move(new Velocity(direction, targetSpeed));
};

export const PLAYER_STATES = {
  WALKING: new PlayerState({
    name: "WALKING",
    maxSpeed: DEFAULT_SPEEDS.WALKING,
    minPitch: -1.2,
    maxPitch: 1.2,
    targetSpeed: function (grad: number) {
      return MathUtils.clamp(this.maxSpeed * grad + this.maxSpeed, 0, this.maxSpeed * 2);
    },
    resolveMove: _resolveMove,
  }),
  RUNNING: new PlayerState({
    name: "RUNNING",
    maxSpeed: DEFAULT_SPEEDS.RUNNING,
    minPitch: -1.2,
    maxPitch: 1.2,
    targetSpeed: function (grad: number) {
      return MathUtils.clamp(this.maxSpeed * grad + this.maxSpeed, 0, this.maxSpeed * 2);
    },
    resolveMove: _resolveMove,
  }),
  SKIING: new PlayerState({
    name: "SKIING",
    maxSpeed: DEFAULT_SPEEDS.SKIING,
    minPitch: -1.2,
    maxPitch: 1.2,
    targetSpeed: function (grad: number) {
      return MathUtils.clamp(this.maxSpeed * grad + this.maxSpeed, 0, this.maxSpeed * 2);
    },
    resolveMove: _resolveMove,
  }),
  DRIVING: new PlayerState({
    name: "DRIVING",
    maxSpeed: DEFAULT_SPEEDS.DRIVING,
    minPitch: -1.2,
    maxPitch: 1.2,
    targetSpeed: function (grad: number) {
      return MathUtils.clamp(this.maxSpeed * grad + this.maxSpeed, 0, this.maxSpeed * 2);
    },
    resolveMove: _resolveMove,
  }),
  FALLING: new PlayerState({
    name: "FALLING",
    maxSpeed: DEFAULT_SPEEDS.FALLING,
    minPitch: -1.2,
    maxPitch: 1.2,
    targetSpeed: function () {
      return this.maxSpeed;
    },
    resolveMove: _doNothing,
  }),
  DROWNING: new PlayerState({
    name: "DROWNING",
    maxSpeed: DEFAULT_SPEEDS.DROWNING,
    minPitch: -1.2,
    maxPitch: 1.2,
    targetSpeed: function () {
      return this.maxSpeed;
    },
    resolveMove: _resolveMove,
  }),
  SLEEPING: new PlayerState({
    name: "SLEEPING",
    maxSpeed: DEFAULT_SPEEDS.SLEEPING,
    minPitch: 0,
    maxPitch: 0,
    targetSpeed: () => 0,
    resolveMove: _doNothing,
  }),
  FREEROAM: new PlayerState({
    name: "FREEROAM",
    maxSpeed: DEFAULT_SPEEDS.FREEROAM,
    minPitch: -1.2,
    maxPitch: 1.2,
    targetSpeed: function (grad: number) {
      return MathUtils.clamp(-this.maxSpeed * grad + this.maxSpeed, 0, this.maxSpeed * 2);
    },
    resolveMove: function (physicsBody: PhysicsBody, direction: Vector3, targetSpeed: number) {
      physicsBody.move(new Velocity(direction, targetSpeed));
    },
  }),
};


