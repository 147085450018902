const DEFAULT_SPEEDS = {
  get DROWNING() {
    return 2;
  },
  get WALKING() {
    return 6;
  },
  get RUNNING() {
    return 10;
  },
  get SKIING() {
    return 20;
  },
  get DRIVING() {
    return 70;
  },
  get FALLING() {
    return 100;
  },
  get FREEROAM() {
    return 100;
  },
  get SLEEPING() {
    return 0;
  }
};

export default DEFAULT_SPEEDS;
