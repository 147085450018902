import { Vector3 } from "three";
import GameTime from "../../GameTime";
import SphereCollider from "../Colliders/SphereCollider";
import PhysicsBody from "../PhysicsBody";
import Velocity from "../Velocity";

/**
 * Resolves a collision with a sphere collider for a single PhysicsBody
 * @param physicsBody the PhysicsBody to resolve
 * @param poi the point of intersection at which the collision occurs
 * @param collisionNormal the normal vector of the collision surface
 * @param collider the specific collider of the PhysicsBody that requires resolving
 */
export default function PhysicsBodySphereResolver(
  physicsBody: PhysicsBody,
  poi: Vector3,
  collisionNormal: Vector3,
  collider: SphereCollider,
  gameTime: GameTime
) {
  if (physicsBody.isStatic) {
    return;
  }

  let reflect = physicsBody.velocity.direction.clone().reflect(collisionNormal);
  let v = new Velocity(reflect, physicsBody.velocity.speed * physicsBody.bounciness);
  let worldCollider = collider.clone().applyMatrix(physicsBody.matrixWorld);
  physicsBody.setPosition(
    poi.clone().add(collisionNormal.multiplyScalar(worldCollider.radius).add(v.resolve(gameTime.deltaTime)))
  );
  physicsBody.move(new Velocity(reflect, physicsBody.velocity.speed * physicsBody.bounciness));
}
