import { Vector3 } from "three";

export default class WayPoint {
  // #region Properties (4)

  public direction: Vector3;
  public position: Vector3;
  public radius: number;
  public t: number;
  public tNormalized: number;
  public userData: any;

  // #endregion Properties (4)

  // #region Constructors (1)

  constructor(position: Vector3, direction?: Vector3, radius: number = 60, userData?: any) {
    this.position = position;
    this.direction = direction || new Vector3();
    this.radius = radius;
    this.userData = userData;
  }

  // #endregion Constructors (1)

  // #region Public Methods (1)

  public distanceTo(v: Vector3) {
    return Math.max(Math.abs(this.position.distanceTo(v)) - this.radius, 0);
  }

  // #endregion Public Methods (1)
}
