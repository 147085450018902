import { Vector3 } from "three";

export default {
  get right() {
    return new Vector3(1, 0, 0);
  },

  get left() {
    return new Vector3(1, 0, 0);
  },

  get up() {
    return new Vector3(0, 1, 0);
  },

  get down() {
    return new Vector3(0, -1, 0);
  },

  get forward() {
    return new Vector3(0, 0, -1);
  },

  get back() {
    return new Vector3(0, 0, 1);
  },
};
