import { Vector3 } from "three";

export default class Force {
  // #region Properties (2)

  private _acceleration: number;
  private _direction: Vector3;

  // #endregion Properties (2)

  // #region Constructors (1)

  constructor(direction?: Vector3, acceleration?: number) {
    if (direction === undefined && acceleration === undefined) {
      this._direction = new Vector3();
      this._acceleration = 0;
    } else if (direction instanceof Vector3 && (Number.isFinite(acceleration) || typeof acceleration === "function")) {
      this._direction = direction;
      this._acceleration = acceleration;
    } else {
      throw "Invalid parameters while instantiating " + Force.name;
    }
  }

  // #endregion Constructors (1)

  // #region Public Accessors (2)

  public get acceleration() {
    return this._acceleration;
  }

  public get direction() {
    return this._direction.clone().normalize();
  }

  // #endregion Public Accessors (2)

  // #region Public Methods (4)

  public add(force: Force) {
    if (force instanceof Force) {
      let r = this.resolve().add(force.resolve());
      let s = r.length();
      return new Force(r.normalize(), s);
    } else {
      throw "Invalid parameters passed to 'add' function";
    }
  }

  public copy(force: Force) {
    this._direction = force.direction.clone();
    this._acceleration = force.acceleration;
  }

  public resolve() {
    return this._direction.clone().multiplyScalar(this._acceleration);
  }

  public sub(force: Force) {
    if (force instanceof Force) {
      let r = this.resolve().sub(force.resolve());
      let s = r.length();
      return new Force(r.normalize(), s);
    } else {
      throw "Invalid parameters passed to 'sub' function";
    }
  }

  // #endregion Public Methods (4)
}
