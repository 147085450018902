import { Vector3 } from "three";

export default class Velocity {
  // #region Properties (4)

  private _direction: Vector3;
  private _speed: number;
  private _temp: Vector3;

  public static from = (vector: Vector3) => {
    let len = vector.length();
    return new Velocity(vector.clone().normalize(), len);
  };

  // #endregion Properties (4)

  // #region Constructors (1)

  // | Function;
  constructor(direction?: Vector3, speed?: number) {
    this._direction;
    this._speed;
    this._temp = new Vector3();

    if (direction === undefined && speed === undefined) {
      this._direction = new Vector3();
      this._speed = 0;
    } else if (direction instanceof Vector3 && Number.isFinite(speed)) {
      this._direction = direction;
      this._speed = Math.round(speed * 10000) / 10000;
    } else if (typeof speed === "function") {
      this._direction = direction;
      this._speed = speed;
    } else {
      throw "Invalid parameters while instantiating " + Velocity.name;
    }
  }

  // #endregion Constructors (1)

  // #region Public Accessors (2)

  public get direction(): Vector3 {
    return this._temp.copy(this._direction).normalize();
  }

  public get speed() {
    return this._speed;
  }

  // #endregion Public Accessors (2)

  // #region Public Methods (6)

  public accelerateTo(v: Velocity, acc: number) {
    let vi = this.resolve();
    let vt = v.resolve();
    let r = vi.lerp(vt, acc);
    let s = r.length();
    return new Velocity(r.normalize(), s);
  }

  public add(v: Velocity) {
    if (v instanceof Velocity) {
      let r = this.resolve().add(v.resolve());
      let s = r.length();
      return new Velocity(r.normalize(), s);
    } else {
      throw "Invalid parameters passed to 'add' function";
    }
  }

  public copy(v: Velocity) {
    this._direction = v.direction;
    this._speed = v.speed;
  }

  public multiplyScalar(s: number) {
    let r = this.resolve().multiplyScalar(s);
    return new Velocity(this._temp.copy(r).normalize(), r.length());
  }

  public resolve(deltaTime?: number) {
    // let s = typeof this._speed === "function" ? this._speed() : this._speed;
    let s = this._speed;
    return deltaTime
      ? this._temp.copy(this._direction).multiplyScalar(s * deltaTime)
      : this._temp.copy(this._direction).multiplyScalar(s);
  }

  public sub(v: Velocity) {
    if (v instanceof Velocity) {
      let r = this.resolve().sub(v.resolve());
      let s = r.length();
      return new Velocity(r.normalize(), s);
    } else {
      throw "Invalid parameters passed to 'sub' function";
    }
  }

  // #endregion Public Methods (6)
}
