import { Box3, Matrix4, Quaternion, Vector3 } from "three";
import ICollider from "./ICollider";

class BoxCollider implements ICollider {
  // #region Properties (5)

  private _halfSize: Vector3;
  private _matrix: Matrix4;
  private _orientation: Quaternion;
  private _position: Vector3;
  private _size: Vector3;

  // #endregion Properties (5)

  // #region Constructors (1)

  /**
   * Describes a box shaped collider to be attached to a PhysicsBody
   * @param size the size of the box collider
   * @param position the offset from the center of the parent PhysicsBody
   * @param orientation the orientation in relation to the parent PhysicsBody
   */
  constructor(size: Vector3, position?: Vector3, orientation?: Quaternion) {
    this._position = position || new Vector3();
    this._size = size;
    this._halfSize = new Vector3().copy(size).divideScalar(2);
    this._orientation = orientation || new Quaternion();
    this._matrix = new Matrix4().compose(this._position, this._orientation, new Vector3(1, 1, 1));
  }

  // #endregion Constructors (1)

  // #region Public Accessors (6)

  public get matrix() {
    return this._matrix;
  }

  public get maxExtent() {
    return new Vector3().addVectors(this._position, this._halfSize);
  }

  public get minExtent() {
    return new Vector3().subVectors(this._position, this._halfSize);
  }

  public get orientation() {
    this._orientation.setFromRotationMatrix(this._matrix);
    return this._orientation;
  }

  public get position() {
    this._position.setFromMatrixPosition(this._matrix);
    return this._position;
  }

  public get size() {
    return this._size;
  }

  // #endregion Public Accessors (6)

  // #region Public Methods (3)

  public applyMatrix(m: Matrix4): BoxCollider {
    this._matrix.multiply(m);
    this._size.multiplyScalar(this._matrix.getMaxScaleOnAxis()); // Only linear scaling allowed
    this._halfSize.copy(this._size).divideScalar(2);
    return this;
  }

  public clone(): BoxCollider {
    return new BoxCollider(this._size.clone(), this.position.clone(), this.orientation.clone());
  }

  public getBoundingBox(): Box3 {
    return new Box3(this.minExtent, this.maxExtent);
  }

  // #endregion Public Methods (3)
}

export default BoxCollider;
