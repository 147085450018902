import { Vector3 } from "three";
import SphereCollider from "../Colliders/SphereCollider";
import INarrowPhaseCollisionResult from "../INarrowPhaseCollisionResult";
import PhysicsBody from "../PhysicsBody";

/**
 * Sphere-Sphere Collision Solver
 * @param colliderA the first collider in a collision pair
 * @param colliderB the second collider in a collision pair
 */
export default function SphereSphereSolver(bodyA: PhysicsBody, colliderA: SphereCollider, bodyB: PhysicsBody, colliderB: SphereCollider): INarrowPhaseCollisionResult {
  let colliderWorldA = colliderA.clone().applyMatrix(bodyA.matrixWorld);
  let colliderWorldB = colliderB.clone().applyMatrix(bodyB.matrixWorld);
  let distanceSq = new Vector3().subVectors(colliderWorldA.position, colliderWorldB.position).lengthSq();
  let combinedRadius = colliderWorldA.radius + colliderWorldB.radius;

  let collisionResult: INarrowPhaseCollisionResult = {
    intersects: false,
    collisionPair: {
      colliderA: colliderA,
      colliderB: colliderB,
    },
  };

  if (distanceSq <= combinedRadius * combinedRadius) {
    let closestPt = new Vector3();
    ClosestPointToSphere(colliderWorldA.position, colliderWorldB.position, colliderWorldB.radius, closestPt);

    collisionResult.intersects = true;
    collisionResult.collisionPair.normalA = new Vector3().subVectors(closestPt, colliderWorldA.position).normalize();
    collisionResult.collisionPair.normalB = new Vector3().subVectors(closestPt, colliderWorldB.position).normalize();
    collisionResult.poi = closestPt;
  }

  return collisionResult;
}

function ClosestPointToSphere(point: Vector3, spherePos: Vector3, sphereRadius: number, result: Vector3) {
  result.subVectors(point, spherePos).normalize().multiplyScalar(sphereRadius).add(spherePos);
}
