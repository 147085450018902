import GameTime from "../GameTime";
import BoxCollider from "./Colliders/BoxCollider";
import SphereCollider from "./Colliders/SphereCollider";
import IBroadPhaseResult from "./IBroadPhaseResult";
import PhysicsBodyBoxResolver from "./Resolvers/PhysicsBodyBoxResolver";
import PhysicsBodySphereResolver from "./Resolvers/PhysicsBodySphereResolver";
import SphereBoxSolver from "./Solvers/SphereBoxSolver";
import SphereSphereSolver from "./Solvers/SphereSphereSolver";

/**
 * Narrow phase of collision detection
 * @param broadPhaseResult the result list from the broad phase
 * @returns true if collisions occurred during the narrow phase
 */
export default function NarrowPhase(broadPhaseResult: IBroadPhaseResult, gameTime: GameTime): boolean {
  let collisionsOccurred = false;

  for (let i = 0; i < broadPhaseResult.pairs.length; i++) {
    let pair = broadPhaseResult.pairs[i];

    // Check for collision between each collider in BodyA with each collider in BodyB.
    for (let j = 0; j < pair.bodyA.colliders.length; j++) {
      for (let k = 0; k < pair.bodyB.colliders.length; k++) {
        let colliderA = pair.bodyA.colliders[j];
        let colliderB = pair.bodyB.colliders[k];

        if (colliderA instanceof SphereCollider && colliderB instanceof SphereCollider) {
          let collisionResult = SphereSphereSolver(pair.bodyA, colliderA, pair.bodyB, colliderB);

          if (collisionResult.intersects) {
            collisionsOccurred = true;

            PhysicsBodySphereResolver(
              pair.bodyA,
              collisionResult.poi,
              collisionResult.collisionPair.normalB,
              colliderA,
              gameTime
            );
            PhysicsBodySphereResolver(
              pair.bodyB,
              collisionResult.poi,
              collisionResult.collisionPair.normalA,
              colliderB,
              gameTime
            );
          }
          //
        } else if (colliderA instanceof SphereCollider && colliderB instanceof BoxCollider) {
          let collisionResult = SphereBoxSolver(pair.bodyA, colliderA, pair.bodyB, colliderB);

          if (collisionResult.intersects) {
            collisionsOccurred = true;

            PhysicsBodySphereResolver(
              pair.bodyA,
              collisionResult.poi,
              collisionResult.collisionPair.normalB.clone(),
              colliderA,
              gameTime
            );

            PhysicsBodyBoxResolver(
              pair.bodyB, 
              collisionResult.poi, 
              collisionResult.collisionPair.normalA,
              colliderB,
              gameTime);
          }
          //
        } else if (colliderA instanceof BoxCollider && colliderB instanceof BoxCollider) {
          // TODO
        }
      }
    }
  }

  return collisionsOccurred;
}
