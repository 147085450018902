import Axios from "axios";
import {
  BoxGeometry,
  FileLoader,
  Group,
  LoadingManager,
  Mesh,
  MeshStandardMaterial,
  Quaternion,
  Scene,
  Vector3,
} from "three";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import IDictionary from "./IDictionary";
import IQuadrant from "./IQuadrant";
import BoxCollider from "./Physics/Colliders/BoxCollider";
import ICollider from "./Physics/Colliders/ICollider";
import SphereCollider from "./Physics/Colliders/SphereCollider";
import PhysicsBody from "./Physics/PhysicsBody";
import PhysicsScene from "./Physics/PhysicsScene";

interface IVector3API {
  // #region Properties (3)

  x: string;
  y: string;
  z: string;

  // #endregion Properties (3)
}

interface IQuaternionAPI {
  // #region Properties (4)

  w: string;
  x: string;
  y: string;
  z: string;

  // #endregion Properties (4)
}

interface IQuadrantEntity {
  // #region Properties (4)

  name: string;
  pos: IVector3API;
  q: IQuaternionAPI;
  scale: IVector3API;

  // #endregion Properties (4)
}

interface IMCGItem {
  // #region Properties (5)

  dimensions: Vector3;
  name: string;
  orientation?: Quaternion;
  position: Vector3;
  type: string;

  // #endregion Properties (5)
}

interface IModelInfo {
  // #region Properties (5)

  isStatic: boolean;
  materialFile: string /** material path/name */;
  mcgFile: string /** model collision geometry path/name */;
  name: string /** model name */;
  objFile: string /** model OBJ path/name */;

  // #endregion Properties (5)
}

export interface IModelLibItem {
  // #region Properties (2)

  isStatic: boolean;
  mcg?: IMCGItem[];
  model: Mesh | Group;

  // #endregion Properties (2)
}

type ModelLibraryData = IModelInfo[];

const modelLibraryData: ModelLibraryData = [
  {
    name: "aircraft02",
    objFile: "/LightAircraft/light_aircraft_02.obj",
    materialFile: "/LightAircraft/light_aircraft_02.mtl",
    mcgFile: "/LightAircraft/light_aircraft_02.mcg", // collision geometry definition
    isStatic: true,
  },
  // {
  //   name: "building01",
  //   objFile: "/Buildings/Building01.obj",
  //   materialFile: "/Buildings/Building01.mtl",
  //   mcgFile: "/Buildings/Building01.mcg",
  //   isStatic: true,
  // },
  {
    name: "radiomast",
    objFile: "/Buildings/radio_mast.obj",
    materialFile: "/Buildings/radio_mast.mtl",
    mcgFile: "/Buildings/radio_mast.mcg",
    isStatic: true,
  },
  {
    name: "house",
    objFile: "/Buildings/House.obj",
    materialFile: "/Buildings/House.mtl",
    mcgFile: "/Buildings/House.mcg",
    isStatic: true,
  },
];

export default class MapContentService {
  // #region Properties (4)

  public activeQuadrants: {};
  public modelLibrary: IDictionary<IModelLibItem>;
  public physicsScene: PhysicsScene;
  public scene: Scene;

  // #endregion Properties (4)

  // #region Constructors (1)

  constructor(scene: Scene, physicsScene: PhysicsScene) {
    console.log("create MapContentService");
    this.activeQuadrants = {};
    this.modelLibrary = {};
    this.scene = scene;
    this.physicsScene = physicsScene;
  }

  // #endregion Constructors (1)

  // #region Public Methods (3)

  public async initModelLibraryAsync() {
    console.log("initialise model library");

    let modelLibrary = this.modelLibrary;
    let loadingManager = new LoadingManager();

    const loadItems = async () => {
      for (let i = 0; i < modelLibraryData.length; i++) {
        const loadItem = async () => {
          let item = modelLibraryData[i];
          console.log(`loading material ${item.name}`);
          let mtl = await MTLLoaderAsync(loadingManager, item);
          console.log(`loading model ${item.name}`);
          let obj = await OBJLoaderAsync(loadingManager, item, mtl);

          obj.name = item.name;

          console.log(`loading collision geometry ${item.name}`);

          let mcg = await MCGLoaderAsync(loadingManager, item, obj);
          modelLibrary[obj.name] = {
            model: obj,
            isStatic: item.isStatic,
            mcg:
              mcg &&
              mcg.map(
                (x: any): IMCGItem => ({
                  name: x.name,
                  dimensions: IVector3APIToVector3(x.dimensions),
                  position: IVector3APIToVector3(x.position),
                  orientation: x.orientation ? IQuaternionAPIToQuaternion(x.orientation) : new Quaternion(),
                  type: x.type,
                })
              ),
          };
        };

        await loadItem();
      }
    };

    await loadItems();

    let cubeGeom = new BoxGeometry(5, 5, 5, 1, 1, 1);
    let cubeMat = new MeshStandardMaterial({
      color: 0xc91006,
      flatShading: true,
    });
    // modelLibrary["box"] = { model: new Mesh(cubeGeom, cubeMat), isStatic: false };
    modelLibrary["box"] = {
      model: new Mesh(cubeGeom, cubeMat),
      isStatic: true,
      mcg: [
        {
          dimensions: new Vector3(5, 35, 5),
          name: "cg_box",
          position: new Vector3(0, 0, 0),
          type: "Box",
          orientation: new Quaternion(),
        },
      ],
    };
    modelLibrary["box"].model.name = "box";
  }

  public readAsync(quadrants: IQuadrant[]) {
    console.log("begin MapContentService.readAsync");

    return new Promise((resolve, reject) => {
      for (let i = 0; i < quadrants.length; i++) {
        let quadIdx = quadrants[i].index;

        if (this.activeQuadrants[quadIdx]) {
          continue;
        }

        Axios.get(`/api/map/objects/${quadIdx}`, {
          responseType: "json",
        })
          .then((response) => response.data)
          .then((quadrant: IQuadrantEntity[]) => {
            this.activeQuadrants[quadIdx] = [];

            quadrant.forEach((item: IQuadrantEntity) => {
              let m = this.spawn(
                item.name,
                IVector3APIToVector3(item.pos),
                IQuaternionAPIToQuaternion(item.q),
                IVector3APIToVector3(item.scale)
              );
              if (!m) {
                reject(`Cannot spawn ${item.name}`);
                return;
              }

              this.activeQuadrants[quadIdx].push(m);
            });

            console.log("resolve MapContentService.readAsync");
            resolve(quadrant);
          });
      }
    });
  }

  public spawn(modelName: string, pos: Vector3, rotation: Quaternion, scale: Vector3) {
    let modelLibItem = this.modelLibrary[modelName];
    let model = modelLibItem.model as any;
    let mcg = modelLibItem.mcg;
    if (!model) {
      return null;
    }

    let m = model.isMesh || model.isGroup ? model.clone() : model.scene.clone();
    m.name = modelName;
    m.userData = { isMapContent: true };
    m.castShadow = m.receiveShadow = true;
    m.traverse((node) => {
      if (node.isMesh) {
        node.castShadow = node.receiveShadow = true;
      }
    });

    if (mcg) {
      let physicsBody = new PhysicsBody();
      physicsBody.isStatic = modelLibItem.isStatic;
      physicsBody.userData = { entity: m };

      this.physicsScene.add(physicsBody);

      mcg.map((c: IMCGItem) => {
        let collider: ICollider = colliderFrom[c.type.toLowerCase()](
          // new Vector3().multiplyVectors(c.dimensions, scale),
          c.dimensions,
          c.position || new Vector3(),
          c.orientation || new Quaternion()
        );
        physicsBody.addCollider(collider);
      });

      physicsBody.setPosition(pos);
      physicsBody.setOrientation(rotation);
      physicsBody.setScale(scale.x);
    } else {
      m.position.set(pos.x, pos.y, pos.z);
      m.quaternion.set(rotation.x, rotation.y, rotation.z, rotation.w);
    }

    m.scale.set(scale.x, scale.y, scale.z);

    this.scene.add(m);
    return m;
  }

  // #endregion Public Methods (3)
}

function IVector3APIToVector3(input: IVector3API): Vector3 {
  return new Vector3(parseFloat(input.x), parseFloat(input.y), parseFloat(input.z));
}

function IQuaternionAPIToQuaternion(input: IQuaternionAPI): Quaternion {
  return new Quaternion(parseFloat(input.x), parseFloat(input.y), parseFloat(input.z), parseFloat(input.w));
}

const colliderFrom = {
  sphere: (dimensions: Vector3, position: Vector3, orientation: Quaternion) =>
    new SphereCollider(dimensions.x / 2, position, orientation),
  box: (dimensions: Vector3, position: Vector3, rotation: Quaternion) =>
    new BoxCollider(dimensions, position, rotation),
};

async function MTLLoaderAsync(loadingManager: LoadingManager, item: IModelInfo): Promise<MTLLoader.MaterialCreator> {
  let mtlLoader = new MTLLoader(loadingManager);
  return new Promise((resolve) => {
    mtlLoader.setPath("/assets/models");
    mtlLoader.load(item.materialFile, (mtl) => {
      console.log(`finished loading material ${item.name}`);
      resolve(mtl);
    });
  });
}

async function OBJLoaderAsync(
  loadingManager: LoadingManager,
  item: IModelInfo,
  mtl: MTLLoader.MaterialCreator
): Promise<Group> {
  let objLoader = new OBJLoader(loadingManager);
  return new Promise((resolve) => {
    objLoader
      .setMaterials(mtl)
      .setPath("/assets/models")
      .load(item.objFile, (obj) => {
        console.log(`finished loading model ${item.name}`);
        resolve(obj);
      });
  });
}

/**
 * Model Collision Geometry Loader
 * @param loadingManager
 * @param item
 * @param obj
 */
async function MCGLoaderAsync(loadingManager: LoadingManager, item: IModelInfo, obj: Group | Mesh): Promise<any> {
  let mcgLoader = new ModelCollisionGeometryLoader(loadingManager);
  return new Promise((resolve) => {
    mcgLoader.setPath("/assets/models").load(item.mcgFile, (mcg) => {
      console.log(`finished loading collision geometry ${item.name}`);
      resolve(mcg);
    });
  });
}

class ModelCollisionGeometryLoader extends FileLoader {
  // #region Constructors (1)

  constructor(loadingManager) {
    super(loadingManager);
    this.setResponseType("json");
  }

  // #endregion Constructors (1)
}
