import { Clock } from "three";
import EventHandler from "./EventHandler";

export default class GameTime {
  private _clock = new Clock();
  private _deltaTime = 0;
  private _status: string;
  private _eventHandler: EventHandler;

  constructor() {
    this._status = "stopped";
    this._deltaTime = 0;
    this._eventHandler = new EventHandler(this);
  }

  get deltaTime() {
    return this._deltaTime;
  }

  get elapsedTime() {
    return this._clock.elapsedTime;
  }

  get status() {
    return this._status;
  }

  start() {
    this._status = "running";
    this._clock.start();
  }

  stop() {
    this._status = "stopped";
    this._deltaTime = 0;
    this._clock.stop();
  }

  update() {
    if (this._status === "running") {
      this._deltaTime = this._clock.getDelta();
      this._eventHandler.trigger("update");
    }
  }

  every(nMilliseconds: number, callback: Function) {
    let lastRemainder = 0;
    this._eventHandler.on("update", () => {
      let r = (this.elapsedTime * 1000) % nMilliseconds;
      if (r < lastRemainder) {
        callback();
      }
      lastRemainder = r;
    });
  }
}
