var _eventId = 0;

export default class EventHandler {
  private _events: any;
  private _parent: any;

  constructor(parent: any) {
    this._parent = parent;
    this._events = {};
  }

  on(eventName: string, fn: Function) {
    if (typeof fn === "function") {
      if (Array.isArray(this._events[eventName])) {
        this._events[eventName][++_eventId] = {
          id: _eventId,
          fn: fn,
        };
      } else {
        this._events[eventName] = [];
        this._events[eventName][++_eventId] = {
          id: _eventId,
          fn: fn,
        };
      }
      return _eventId;
    }
  }

  off(eventName: string, eventId: number) {
    if (this._events[eventName][eventId]) {
      delete this._events[eventName][eventId];
    }
  }

  trigger(eventName: string) {
    if (Array.isArray(this._events[eventName])) {
      this._events[eventName].forEach((item) => item.fn(this._parent));
    }
  }
}
