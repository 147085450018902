import { Box3 } from "three";
import ICollider from "./Colliders/ICollider";

export default class Bounds extends Box3 {
  // #region Constructors (1)

  constructor(colliders: Array<ICollider>) {
    super();

    if (colliders) {
      for (let i = 0; i < colliders.length; i++) {
        let collider = colliders[i];
        let bbox = collider.getBoundingBox();
        this.union(bbox);
      }
    }
  }

  // #endregion Constructors (1)
}
