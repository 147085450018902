import { Matrix4, Vector3 } from "three";
import BoxCollider from "../Colliders/BoxCollider";
import SphereCollider from "../Colliders/SphereCollider";
import INarrowPhaseCollisionResult from "../INarrowPhaseCollisionResult";
import PhysicsBody from "../PhysicsBody";

/**
 * Sphere-Box Collision Solver
 * @param colliderA the first collider in a collision pair
 * @param colliderB the second collider in a collision pair
 */
export default function SphereBoxSolver(
  bodyA: PhysicsBody,
  colliderA: SphereCollider,
  bodyB: PhysicsBody,
  colliderB: BoxCollider
): INarrowPhaseCollisionResult {
  // Transform colliderA into local space of colliderB so we can perform a more simple sphere-AABB test
  let localAToLocalB = new Matrix4().getInverse(bodyB.matrixWorld).multiply(bodyA.matrixWorld);
  let colliderAInLocalBSpace = colliderA.clone().applyMatrix(localAToLocalB);

  let result: INarrowPhaseCollisionResult = {
    intersects: false,
    collisionPair: {
      colliderA: colliderA,
      colliderB: colliderB,
    },
  };

  // now determine point of intersection and face normal
  let poi = new Vector3();
  let collidersIntersect =
    DistancePointToAABB(colliderAInLocalBSpace.position, colliderB) <= colliderAInLocalBSpace.radius;

  if (collidersIntersect) {
    ClosestPointToAABB(colliderAInLocalBSpace.position, colliderB, poi);

    result.intersects = true;

    // Transform point of intersection back from B's local space to world space
    result.poi = poi.applyMatrix4(bodyB.matrixWorld);
    result.collisionPair.normalA = new Vector3()
      .subVectors(poi, colliderA.clone().applyMatrix(bodyA.matrixWorld).position)
      .normalize();
    result.collisionPair.normalB = result.collisionPair.normalA.clone().negate();
  }

  return result;
}

function ClosestPointToAABB(point: Vector3, box: BoxCollider, result: Vector3) {
  result.copy(point).clamp(box.minExtent, box.maxExtent);
}

function DistancePointToAABB(point: Vector3, box: BoxCollider): number {
  let closestPt = new Vector3();
  ClosestPointToAABB(point, box, closestPt);
  return closestPt.sub(point).length();
}
