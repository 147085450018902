import { MathUtils, Vector3 } from "three";
import WayPoint from "./WayPoint";

export default class WayPointPath {
  // #region Properties (4)

  private _current: number;
  private _isNormalized: boolean;
  private _waypoints: WayPoint[];

  public static DEFAULT_STEP = 0.0001;

  // #endregion Properties (4)

  // #region Constructors (1)

  constructor(waypoints?: any[]) {
    this._waypoints = waypoints
      ? waypoints.map((w) =>
          w instanceof WayPoint
            ? w
            : new WayPoint(
                new Vector3(w.position.x, w.position.y, w.position.z),
                w.direction ? new Vector3(w.direction.x, w.direction.y, w.direction.z) : new Vector3(),
                w.radius,
                w.userData
              )
        )
      : [];

    this._isNormalized = false;
    this._current = 0;
  }

  // #endregion Constructors (1)

  // #region Public Accessors (4)

  public get current(): WayPoint {
    return this._waypoints[this._current];
  }

  public get isNormalized() {
    return this._isNormalized;
  }

  public get length() {
    return this._waypoints.length;
  }

  public get next(): WayPoint {
    this._current++;
    if (this._current < this._waypoints.length) {
      return this._waypoints[this._current];
    }
    return null;
  }

  // #endregion Public Accessors (4)

  // #region Public Methods (4)

  /**
   * Returns an interpolated WayPoint at value 't' along the path (0 <= t <= 1)
   * @param t represents the position along the path to interpolate.  Must be a value between 0 and 1.
   */
  public at(t: number): WayPoint {
    t = MathUtils.clamp(t, 0, 1);

    if (this._isNormalized) {
      return this._at(this.denormalizeT(t));
    }

    return this._at(t);
  }

  /**
   * Normalize the path
   * @param nWayPoints Number of evenly distributed way-points to create
   * @description For a linear increase in 't' the distance along the path also increases linearly after normalization
   */
  public normalize(nWayPoints: number) {
    let lengths = [];
    let totalPathLength = this.pathLength();
    let pl = 0;

    for (let i = 0; i < this._waypoints.length - 1; i++) {
      this._waypoints[i].t = i / (this._waypoints.length - 1);
      pl = this.pathLength(WayPointPath.DEFAULT_STEP, 0, i + 1);
      lengths.push(pl);
    }

    this._waypoints[this._waypoints.length - 1].t = 1;
    this._waypoints[0].tNormalized = 0;

    // Recalculate 't' for linear scale
    for (let i = 0; i < lengths.length; i++) {
      let w1 = this._waypoints[i + 1];
      w1.tNormalized = lengths[i] / totalPathLength;
    }

    this._isNormalized = true;

    let newWayPoints = [];
    let tStep = 1 / nWayPoints;
    for (let t = 0; t < 1 + tStep; t += tStep) {
      let w = this.at(t);
      w.t = w.tNormalized = t;
      newWayPoints.push(w);
    }
    this._waypoints = newWayPoints;
  }

  /**
   * Approximate the length of the way-point path
   * @param step the step size (0.0001 < step < 0.1)
   * @param startIndex the index of the first waypoint to measure from
   * @param endIndex the index of the last waypoint to measure to
   */
  public pathLength(step?: number, startIndex?: number, endIndex?: number): number {
    let l = 0;
    let si = (startIndex || 0) / (this._waypoints.length - 1);
    let ei = (endIndex || this._waypoints.length - 1) / (this._waypoints.length - 1);
    let d = new Vector3();

    step = Math.min(Math.max(step || WayPointPath.DEFAULT_STEP, 0.0001), 0.1); // clamp the step value
    let last: WayPoint;

    for (let t = si; t <= ei; t += step) {
      let w = this.at(t);

      if (last) {
        l += d.subVectors(w.position, last.position).length();
      }

      last = w;
    }

    return l;
  }

  public reset() {
    this._current = 0;
  }

  // #endregion Public Methods (4)

  // #region Private Methods (2)

  private _at(t: number): WayPoint {
    let ti = t * (this._waypoints.length - 1);
    let t1 = this._current = Math.floor(ti);
    let t2 = Math.ceil(ti);

    if (t1 === t2 || t2 >= this._waypoints.length) {
      return this._waypoints[t1];
    }

    let w0 = this._waypoints[Math.max(t1 - 1, 0)];
    let w1 = this._waypoints[t1];
    let w2 = this._waypoints[t2];
    let w3 = this._waypoints[Math.min(t2 + 1, this._waypoints.length - 1)];

    let mu = ti - t1;
    let ip = Vector3CubicInterpolate([w0.position, w1.position, w2.position, w3.position], mu);
    let id = Vector3CubicInterpolate([w0.direction, w1.direction, w2.direction, w3.direction], mu);
    let ir = CubicInterpolate(w0.radius, w1.radius, w2.radius, w3.radius, mu);
    let iud = {};

    if (w0.userData && w1.userData && w2.userData && w3.userData) {
      for (let prop in w0.userData) {
        let p0 = w0.userData[prop];
        let p1 = w1.userData[prop];
        let p2 = w2.userData[prop];
        let p3 = w3.userData[prop];
        iud[prop] = CubicInterpolate(p0, p1, p2, p3, mu);
      }
    }

    if (ip) {
      return new WayPoint(ip, id, ir, iud);
    }

    return null;
  }

  private denormalizeT(tNormalized: number): number {
    let t = tNormalized;
    let idx = this._waypoints.findIndex((x) => x.tNormalized >= t);

    if (idx > 0) {
      // If path has been normalised, translate 't' to normalised value
      let wp1 = this._waypoints[idx - 1];
      let wp2 = this._waypoints[idx];
      let m = (wp2.t - wp1.t) / (wp2.tNormalized - wp1.tNormalized);
      let c = wp1.t - m * wp1.tNormalized;
      t = m * t + c;
    }

    return t;
  }

  // #endregion Private Methods (2)
}

function CubicInterpolate(y0: number, y1: number, y2: number, y3: number, mu: number): number {
  let mu2 = mu * mu;
  let mu3 = mu * mu * mu;
  let a0 = -0.5 * y0 + 1.5 * y1 - 1.5 * y2 + 0.5 * y3;
  let a1 = y0 - 2.5 * y1 + 2 * y2 - 0.5 * y3;
  let a2 = -0.5 * y0 + 0.5 * y2;
  let a3 = y1;

  return a0 * mu3 + a1 * mu2 + a2 * mu + a3;
}

function Vector3CubicInterpolate(points: Vector3[], mu: number): Vector3 {
  if (points.length < 4) return;

  let r = new Vector3();

  for (let n = 0; n < 3; n++) {
    let c1 = points[0].getComponent(n);
    let c2 = points[1].getComponent(n);
    let c3 = points[2].getComponent(n);
    let c4 = points[3].getComponent(n);
    r.setComponent(n, CubicInterpolate(c1, c2, c3, c4, mu));
  }

  return r;
}
