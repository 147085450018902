export interface IStateful<T> {
  set(newState: T): boolean;
  is(comparitor: T): boolean;
  isAnyOf(...comparitors: T[]): boolean;
}

export type TransitionCallback<T> = (prvState: T, newState: T) => void;

export interface ITransition<T> {
  from?: T;
  to?: T;
  then: TransitionCallback<T>;
}

export default class Stateful<T extends object> implements IStateful<T> {
  private _transitions: ITransition<T>[];
  private _current: T;

  public static from<T extends object>(src: T, transitions?: ITransition<T>[]): Stateful<T> & T {
    return new Proxy(new Stateful<T>(src, transitions || []), {
      get(target, prop) {
        if (["set", "_current", "_transitions", "isStateful", "is", "isAnyOf"].some((x) => x === prop)) {
          return (target as any)[prop];
        }
        return (target._current as any)[prop];
      },
    }) as any;
  }

  public static when<T extends object>(state: Stateful<T> & T) {
    let _src: T[] = [],
      _target: T[] = [];

    let r = {
      from: (...src: T[]) => {
        _src = src;
        return r;
      },
      to: (...target: T[]) => {
        _target = target;
        return r;
      },
      then: (callback: TransitionCallback<T>) => {
        if (_src.length === 0) {
          _src.push(null);
        }
        if (_target.length === 0) {
          _target.push(null);
        }

        _src.forEach((src) => {
          _target.forEach((target) => {
            state._transitions.push({
              from: src,
              to: target,
              then: callback,
            });
          });
        });
      },
    };

    return {
      changes: r,
    };
  }

  private constructor(src: T, transitions: ITransition<T>[]) {
    this._current = src;
    this._transitions = transitions;
  }

  set(newState: T): boolean {
    let matchingTransitions = this._transitions.filter(
      (x: ITransition<T>) =>
        (x.from == undefined || x.from === this._current) && (x.to == undefined || x.to === newState)
    );

    if (matchingTransitions.length > 0) {
      let prvState = this._current;
      this._current = newState;
      matchingTransitions.forEach((t: ITransition<T>) => t.then(prvState, newState));
      return true;
    }
    return false;
  }

  is(comparitor: T): boolean {
    return this._current === comparitor;
  }

  isAnyOf(...comparitors: T[]): boolean {
    return comparitors.some((x) => x === this._current);
  }
}
