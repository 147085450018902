import { Box3, Matrix4, Quaternion, Vector3 } from "three";
import ICollider from "./ICollider";

/**
 * @description SphereCollider
 */
export default class SphereCollider implements ICollider {
  // #region Properties (5)

  private _matrix: Matrix4;
  private _orientation: Quaternion;
  private _position: Vector3;
  private _radius: number;
  private _radiusSq: number;

  // #endregion Properties (5)

  // #region Constructors (1)

  /**
   * Describes a sphere shaped collider to be attached to a PhysicsBody
   * @param radius the radius
   * @param position the offset from the center of the parent PhysicsBody
   * @param orientation the orientation in relation to the parent PhysicsBody
   */
  constructor(radius: number, position?: Vector3, orientation?: Quaternion) {
    this._position = position || new Vector3();
    this._orientation = orientation || new Quaternion();
    this._radius = radius;
    this._radiusSq = this._radius * this._radius;
    this._matrix = new Matrix4().compose(this._position, this._orientation, new Vector3(1, 1, 1));
  }

  // #endregion Constructors (1)

  // #region Public Accessors (5)

  /** matrix describing the local transform */
  public get matrix() {
    return this._matrix;
  }

  /** local orientation of the collider relative to the parent PhysicsBody */
  public get orientation() {
    this._orientation.setFromRotationMatrix(this._matrix); // OK to use this method here since colliders don't have scale
    return this._orientation;
  }

  /** local position of the collider relative to the parent PhysicsBody */
  public get position() {
    this._position.setFromMatrixPosition(this._matrix);
    return this._position;
  }

  /** radius of the SphereCollider */
  public get radius() {
    return this._radius;
  }

  /** radius squared of the SphereCollider */
  public get radiusSq() {
    return this._radiusSq;
  }

  // #endregion Public Accessors (5)

  // #region Public Methods (2)

  public applyMatrix(m: Matrix4): SphereCollider {
    this._matrix = m.clone().multiply(this._matrix);
    this._radius = this._radius * this._matrix.getMaxScaleOnAxis();
    return this;
  }

  public clone(): SphereCollider {
    return new SphereCollider(this._radius, this.position.clone(), this.orientation.clone());
  }

  public getBoundingBox(): Box3 {
    let bbox = new Box3();
    bbox.set(this._position, this._position);
    bbox.expandByScalar(this._radius);
    return bbox;
  }

  // #endregion Public Methods (2)
}
