import Axios from "axios";
import {
  AmbientLight,
  DirectionalLight,
  FogExp2,
  GammaEncoding,
  MathUtils,
  Object3D,
  PCFSoftShadowMap,
  PerspectiveCamera,
  Scene,
  Vector3,
  WebGLRenderer,
} from "three";
import Debug, { debug } from "./Debug";
import DollyCamera from "./DollyCamera";
import GameTime from "./GameTime";
import IGame from "./IGame";
import MapContentService from "./MapContentService";
import PhysicsBody from "./Physics/PhysicsBody";
import PhysicsScene from "./Physics/PhysicsScene";
import TerrainPhysicsBody from "./Physics/TerrainPhysicsBody";
import Player from "./Player";
import PlayerController from "./PlayerController";
import { PLAYER_STATES } from "./PlayerState";
import Stats from "./Stats";
import Terrain from "./Terrain";

export default Game;

async function Game(container: HTMLElement): Promise<IGame> {
  return new Promise((resolve) => {
    let gameHasFocus = false;
    let scene = new Scene();

    let renderer = new WebGLRenderer({
      antialias: true,
    });
    renderer.outputEncoding = GammaEncoding;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap;
    // renderer.setClearColor(0xabdbeb);
    renderer.setClearColor(0xb5eeff);
    renderer.setSize(window.innerWidth / 4, window.innerHeight / 4, false);

    container.appendChild(renderer.domElement);

    // Stats
    let stats = Stats();
    stats.setMode(0);
    stats.domElement.classList.add("stats-js");
    document.body.appendChild(stats.domElement);

    // Debug
    (window as any).debug = new Debug(scene);

    Axios.get("/api/map/head")
      .then((response) => response.data)
      .then(async (mapHeader) => {
        console.log("got /api/map/head");

        let terrain = new Terrain(renderer, scene, {
          mapSize: mapHeader.mapSize,
          viewDistance: mapHeader.viewSize,
          subdivisionSize: 16,
          maxHeight: mapHeader.maxHeight,
          ocean: {
            height: 240,
            color: "#11378A",
          },
        });

        await terrain.loadAsync("/images/terrain02.png");
        let terrainPhysicsBody = new TerrainPhysicsBody({
          mapSize: terrain.mapSize,
          viewSize: terrain.viewSize,
          subdivisions: terrain.subdivisions,
          quadrantSize: 2048,
        });

        let physicsScene = new PhysicsScene();
        physicsScene.add(terrainPhysicsBody);
        PhysicsBody.onUpdate = (gameTime, physicsBody) => {
          let entity = physicsBody.userData.entity as Object3D;
          if (entity) {
            entity.position.copy(physicsBody.position);
            entity.quaternion.copy(physicsBody.orientation);
            entity.scale.copy(physicsBody.scale);
          }
        };

        let player = new DollyCamera(renderer.domElement);
        let camera = player;

        player.physicsBody.setPosition(new Vector3(9459, 265, -14668));
        // player.physicsBody.setOrientation(
        //   new Quaternion(-0.01702, 0.97655, 0.08104, 0.19872).normalize()
        //   // new Quaternion(-0.01702601088131484, 0.976550282802887, 0.08104067952009769, 0.19872611397094142)
        // );
        scene.add(player);
        physicsScene.add(player.physicsBody);

        // let playerController = initPlayerControls(player);
        let lighting = initLighting(player, terrain);
        scene.add(lighting.ambientLight);
        scene.add(lighting.sun);

        scene.fog = createFog();

        // let miniMap = new MiniMap({
        //   container: document.getElementById("minimap"),
        //   mapImagePath: "./images/minimap02.png",
        //   mapSize: terrain.mapSize,
        //   pointerScale: terrain.mapSize / 82,
        //   zoomFactor: 3,
        // });

        let quadrants = await terrainPhysicsBody.readAsync(player.physicsBody.position);
        let mapContentService = new MapContentService(scene, physicsScene);
        await mapContentService.initModelLibraryAsync();
        await mapContentService.readAsync(quadrants);

        console.log("initialise GameTime");
        let gameTime = new GameTime();
        gameTime.every(5000, () => updateMapCollisionGeometryAndObjects(terrainPhysicsBody, mapContentService, player));

        let gameContainer = container;
        container.addEventListener("click", () => {
          // if (!gameHasFocus) {
          //   gameContainer.requestPointerLock();
          // }
          gameHasFocus = !gameHasFocus;
          if (gameHasFocus) {
            let coverText = document.getElementsByClassName("cover-text")[0] as HTMLElement;
            if (coverText) {
              coverText.remove();
            }
            gameTime.start();
            audio.play();
          } else {
            gameTime.stop();
            audio.pause();
          }
        });

        let audio = new Audio("/assets/audio/The-Berg-Intro.mp3");

        // document.addEventListener("pointerlockchange", () => {
        //   gameHasFocus = !gameHasFocus;
        //   // playerController.enabled = !playerController.enabled;
        //   if (gameHasFocus) {
        //     let coverText = document.getElementsByClassName("cover-text")[0] as HTMLElement;
        //     if (coverText) {
        //       coverText.remove();
        //     }
        //     gameTime.start();
        //     audio.play();
        //   } else {
        //     gameTime.stop();
        //     audio.pause();
        //   }
        // });

        // console.log("initialise map editor");
        // let editor = new MapEditor(
        //   player,
        //   playerController,
        //   scene,
        //   mapContentService,
        //   terrainPhysicsBody,
        //   physicsScene
        // );

        console.log("resolve Game");
        resolve({
          player,
          scene,
          renderer,
          terrain,
          camera,
          mapContentService,
          terrainPhysicsBody,
          physicsScene,
        });

        physicsScene.update(gameTime);
        terrain.update(player, gameTime);

        let playerDirection = new Vector3();
        player.getWorldDirection(playerDirection);
        // miniMap.render(player.position, playerDirection);

        // gameHasFocus = true;
        // gameTime.start();
        // audio.play();

        console.log("begin renderLoop");

        debug().showDebugMeshes = false;

        const renderLoop = () => {
          stats.begin();

          gameTime.update();

          if (gameHasFocus) {
            physicsScene.update(gameTime);
            terrain.update(player, gameTime);
          }

          (lighting.sun as any).update();
          // debug().update();

          requestAnimationFrame(renderLoop);
          renderer.setRenderTarget(null);
          renderer.render(scene, camera);

          if (gameHasFocus) {
            player.getWorldDirection(playerDirection);
            // miniMap.render(player.position, playerDirection);
          }

          stats.end();
        };

        renderLoop();
      });
  });
}

async function updateMapCollisionGeometryAndObjects(
  terrainPhysicsBody: TerrainPhysicsBody,
  mapContentService: MapContentService,
  camera: PerspectiveCamera
) {
  let quadrants = await terrainPhysicsBody.readAsync(camera.position);
  mapContentService.readAsync(quadrants);
}

function initLighting(camera: PerspectiveCamera, terrain: Terrain) {
  console.log("Initialise lighting");
  let ambientLight = new AmbientLight(0xdaeeff, 0.6);

  // let sun = new DirectionalLight(0xdaeeff, 0.6);
  let sun = new DirectionalLight(0xb5eeff, 0.6);
  sun.castShadow = true;
  sun.shadow.mapSize.width = terrain.viewSize;
  sun.shadow.mapSize.height = terrain.viewSize;
  sun.shadow.radius = 8;

  let shadowCameraBounds = 500;
  sun.shadow.camera.near = 0.1;
  sun.shadow.camera.far = camera.far;
  sun.shadow.camera.right = shadowCameraBounds;
  sun.shadow.camera.left = -shadowCameraBounds;
  sun.shadow.camera.top = shadowCameraBounds;
  sun.shadow.camera.bottom = -shadowCameraBounds;
  sun.target = camera;

  return {
    ambientLight,
    sun,
  };
}

function createFog() {
  console.log("Create fog");
  // return new FogExp2(0xabdbeb, 0.003);
  return new FogExp2(0xb5eeff, 0.003);
}

function initPlayerControls(player: Player) {
  console.log("Creating PlayerController");
  return new PlayerController({
    useMouseLook: true,
    keyMap: {
      turnleft: "37",
      turnright: "39",
      lookup: "38",
      lookdown: "40",
      forward: "87",
      backward: "83",
      strafeleft: "65",
      straferight: "68",
      run: "16",
    },
    actions: {
      turnleft: () => {
        player.addYaw(MathUtils.degToRad(1));
      },
      turnright: () => {
        player.addYaw(MathUtils.degToRad(-1));
      },
      forward: (pc, keyup) => {
        player.move().forward();
      },
      backward: () => {
        player.move().backward();
      },
      lookup: () => {
        player.addPitch(MathUtils.degToRad(1));
      },
      lookdown: () => {
        player.addPitch(MathUtils.degToRad(-1));
      },
      strafeleft: () => {
        player.move().left();
      },
      straferight: () => {
        player.move().right();
      },
      mousemove: (pc, ev) => {
        player.addPitch(MathUtils.degToRad(-ev.movementY / 2));
        player.addYaw(MathUtils.degToRad(-ev.movementX / 2));
      },
      run: (pc, keyup) => {
        if (player.playerState.is(PLAYER_STATES.FREEROAM)) {
          return;
        }

        if (keyup) {
          player.playerState.set(PLAYER_STATES.WALKING);
        } else {
          player.playerState.set(PLAYER_STATES.RUNNING);
        }
      },
    },
    enabled: false,
  });
}
