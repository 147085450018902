import Axios from "axios";
import { Ray, Triangle, Vector2, Vector3 } from "three";
import DEFAULT_VECTORS from "../DEFAULT_VECTORS";
import PhysicsBody from "./PhysicsBody";

interface IQuadrant {
  // #region Properties (3)

  centre: { x: number; z: number };
  collisionGeom?: any;
  index: number;

  // #endregion Properties (3)
}

export interface ITerrainPhysicsBodyParams {
  mapSize: number;
  viewSize: number;
  subdivisions: number;
  quadrantSize: number;
}

export default class TerrainPhysicsBody extends PhysicsBody {
  // #region Properties (6)

  public mapSize: number;
  public quadrantLoadZoneSize: number;
  public quadrantSize: number;
  public quadrants: IQuadrant[];
  public subdivisions: number;
  public viewSize: number;

  // #endregion Properties (6)

  // #region Constructors (1)

  constructor(args: ITerrainPhysicsBodyParams) {
    super();

    this.quadrants = [];
    this.mapSize = args.mapSize;
    this.viewSize = args.viewSize;
    this.subdivisions = args.subdivisions;
    this.quadrantSize = args.quadrantSize;
    this.quadrantLoadZoneSize = args.quadrantSize;
  }

  // #endregion Constructors (1)

  // #region Public Methods (3)

  public heightAt(x: number, z: number) {
    let r = this.quadrantAndFaceIndexFromPos(x, z);

    let quadrant = this.quadrants[r.quadrant.index];
    if (!quadrant) {
      return false;
    }

    let face = quadrant.collisionGeom.faces[r.faceIndex];
    if (!face) return;
    // Then use the associated vertices to calc the intersection
    let t = new Triangle(
      quadrant.collisionGeom.vertices[face.a],
      quadrant.collisionGeom.vertices[face.b],
      quadrant.collisionGeom.vertices[face.c]
    );

    // let p = {}; // , tNormal = new Vector3();
    // t.getNormal( tNormal );
    // t.intersectRay(new Vector3(x, -z, 0), new Vector3(0, 0, 1), p);
    let p = new Vector3();
    let ray = new Ray(new Vector3(x, -z, 0), DEFAULT_VECTORS.back);
    if (ray.intersectTriangle(t.a, t.b, t.c, false, p) === null) {
      return false;
    } else {
      return {
        h: p.z,
        normal: new Vector3(face.normal.x, face.normal.z, -face.normal.y),
      };
    }
  }

  public quadrantAndFaceIndexFromPos(x, z) {
    let q = quadrantsFromXZ(x, z, this)[0];

    let halfSize = this.quadrantSize / 2;
    let quadSubdiv = (this.quadrantSize / this.viewSize) * this.subdivisions;
    let localPos = new Vector3(x - q.centre.x, 0, z - q.centre.z);
    localPos.add(new Vector3(halfSize, 0, halfSize));
    let t = localPos.divideScalar(this.quadrantSize);
    t.multiplyScalar(quadSubdiv);
    // Determine a 'grid' position from the local position
    let g = new Vector2(Math.floor(t.x), Math.floor(t.z));
    // And determine which half of the grid square the co-ords are in
    let faceOffset = 1 - frac(t.z) <= frac(t.x) ? 1 : 0;
    // Use this to calculate the face array index.
    let idx = (g.x + g.y * quadSubdiv) * 2 + faceOffset;

    return {
      gridCoords: g,
      faceIndex: idx,
      quadrant: q,
    };
  }

  public async readAsync(pos) {
    console.log("read terrain collision geometry");
    let quadrants = quadrantsFromXZ(pos.x, pos.z, this);

    return (Promise as any)
      .allSettled(
        quadrants.map((quad) => {
          if (this.quadrants[quad.index] === undefined) {
            return Axios.get(`/api/map/collisiongeometry/${quad.centre.x}/${quad.centre.z}`).then((response) => {
              this.quadrants[quad.index] = {
                index: quad.index,
                centre: quad.centre,
                collisionGeom: response.data,
              };
            });
          }
        })
      )
      .then((results) => {
        // Unload any quadrants we're no longer using.
        this.quadrants.forEach((quad, idx) => {
          if (quadrants.filter((x) => x.index === idx).length === 0) {
            delete this.quadrants[idx];
          }
        });

        console.log("finished loading terrain collision geometry");
        return Promise.resolve(quadrants);
      });
  }

  // #endregion Public Methods (3)
}

function quadrantCentreFromIndex(idx: number, terrain: TerrainPhysicsBody) {
  let halfSize = terrain.mapSize / 2;
  let quadrantsPerRow = terrain.mapSize / terrain.quadrantSize;
  let qr = Math.floor(idx / quadrantsPerRow);
  let qp = idx * terrain.quadrantSize - terrain.mapSize * qr + terrain.quadrantSize / 2;

  return {
    x: qp - halfSize,
    z: qr * terrain.quadrantSize + terrain.quadrantSize / 2 - halfSize,
  };
}

function quadrantFromXZ(x: number, z: number, terrain: TerrainPhysicsBody): IQuadrant {
  let halfSize = terrain.mapSize / 2;
  let quadrantsPerRow = terrain.mapSize / terrain.quadrantSize;
  let adjX = x + halfSize;
  let adjZ = z + halfSize;
  let q = quadrantsPerRow * Math.floor(adjZ / terrain.quadrantSize) + Math.floor(adjX / terrain.quadrantSize);

  return {
    index: q,
    centre: quadrantCentreFromIndex(q, terrain),
  };
}

function quadrantsFromXZ(x: number, z: number, terrain: TerrainPhysicsBody): IQuadrant[] {
  let mainQuad = quadrantFromXZ(x, z, terrain);
  let result = [mainQuad];

  // Determine other quadrants near to given position (used for preloading)
  let diff = {
    x: x - mainQuad.centre.x,
    z: z - mainQuad.centre.z,
  };
  let threshold = (terrain.quadrantSize - terrain.quadrantLoadZoneSize) / 2;
  let xAdjQuad: IQuadrant, zAdjQuad: IQuadrant;

  if (Math.abs(diff.x) > threshold) {
    xAdjQuad =
      Math.sign(diff.x) >= 0
        ? quadrantFromXZ(x + terrain.quadrantLoadZoneSize, z, terrain)
        : quadrantFromXZ(x - terrain.quadrantLoadZoneSize, z, terrain);

    result.push(xAdjQuad);
  }
  if (Math.abs(diff.z) > threshold) {
    zAdjQuad =
      Math.sign(diff.z) >= 0
        ? quadrantFromXZ(x, z + terrain.quadrantLoadZoneSize, terrain)
        : quadrantFromXZ(x, z - terrain.quadrantLoadZoneSize, terrain);

    result.push(zAdjQuad);
  }

  if (xAdjQuad && zAdjQuad) {
    let idx = xAdjQuad.index + zAdjQuad.index - mainQuad.index;
    let xzAdjQuad: IQuadrant = {
      index: idx,
      centre: quadrantCentreFromIndex(idx, terrain),
    };
    result.push(xzAdjQuad);
  }

  return result;
}

function frac(n: number) {
  return n - Math.floor(n);
}
