import { MathUtils, PerspectiveCamera, Quaternion, Vector3 } from "three";
import DEFAULT_VECTORS from "./DEFAULT_VECTORS";
import GameTime from "./GameTime";
import path01 from "./path01";
import SphereCollider from "./Physics/Colliders/SphereCollider";
import PhysicsBody from "./Physics/PhysicsBody";
import PlayerState, { PLAYER_STATES } from "./PlayerState";
import Stateful from "./Stateful";
import WayPoint from "./WayPoint";
import WayPointPath from "./WayPointPath";

class DollyCamera extends PerspectiveCamera {
  // #region Properties (17)

  private _gradient: number;
  private _pitch: number;
  private _prevPlayerState: PlayerState;
  private _qPitch: Quaternion;
  private _qRoll: Quaternion;
  private _qYaw: Quaternion;
  private _roll: number;
  private _yaw: number;
  private _wayPointPath: WayPointPath;
  private _lastWayPoint: WayPoint;
  private _t: number;

  public physicsBody: PhysicsBody;
  public playerState: PlayerState & Stateful<PlayerState>;
  public positionOffset: Vector3;
  public targetSpeed: number;
  public canvas: HTMLCanvasElement;
  public coverText: HTMLElement;

  // #endregion Properties (17)

  // #region Constructors (1)

  constructor(canvasElement: HTMLCanvasElement) {
    console.log("Creating Player");
    super(65, window.innerWidth / window.innerHeight, 0.01, 6000);

    this.canvas = canvasElement;
    this.coverText = document.getElementById("cover");

    this._wayPointPath = new WayPointPath(path01 as WayPoint[]);
    this._wayPointPath.normalize(40);
    this._t = 0.04;

    this._yaw = Math.PI * 0.85;
    this._pitch = this._wayPointPath.at(0).direction.y;
    this._roll = 0;
    this._qPitch = new Quaternion();
    this._qYaw = new Quaternion();
    this._qRoll = new Quaternion();
    this._gradient = 0;

    this.physicsBody = new PhysicsBody();
    this.physicsBody.acceleration = 6;
    this.physicsBody.followTerrain = false;
    this.physicsBody.addCollider(new SphereCollider(3));
    this.physicsBody.userData = { entity: this };
    this.physicsBody.onPreUpdate = this.update.bind(this);

    this.playerState = Stateful.from(PLAYER_STATES.WALKING);
    this.name = "Player";
    this.targetSpeed = 0;
    this.positionOffset = new Vector3();

    // Configure valid player state transitions
    Stateful.when(this.playerState)
      .changes.from(PLAYER_STATES.WALKING)
      .to(PLAYER_STATES.RUNNING)
      .then(() => {});

    Stateful.when(this.playerState)
      .changes.from(PLAYER_STATES.RUNNING)
      .to(PLAYER_STATES.WALKING)
      .then(() => {});

    Stateful.when(this.playerState)
      .changes.from(PLAYER_STATES.WALKING)
      .to(PLAYER_STATES.SKIING)
      .then(() => {});

    Stateful.when(this.playerState)
      .changes.from(PLAYER_STATES.SKIING)
      .to(PLAYER_STATES.WALKING)
      .then(() => {});

    Stateful.when(this.playerState)
      .changes.from(PLAYER_STATES.WALKING, PLAYER_STATES.RUNNING, PLAYER_STATES.SKIING)
      .to(PLAYER_STATES.FALLING)
      .then(() => {});

    Stateful.when(this.playerState)
      .changes.from(PLAYER_STATES.FALLING)
      .to(PLAYER_STATES.WALKING, PLAYER_STATES.RUNNING, PLAYER_STATES.SKIING)
      .then(() => {});
  }

  // #endregion Constructors (1)

  // #region Public Accessors (4)

  public get backward() {
    return this.forward.negate();
  }

  public get forward() {
    return DEFAULT_VECTORS.forward.applyAxisAngle(DEFAULT_VECTORS.up, this._yaw).normalize();
  }

  public get left() {
    return this.right.negate();
  }

  public get right() {
    return new Vector3().crossVectors(this.forward, DEFAULT_VECTORS.up).normalize();
  }

  // #endregion Public Accessors (4)

  // #region Public Methods (6)

  public addPitch(radians) {
    this._pitch = MathUtils.clamp(this._pitch + radians, this.playerState.minPitch, this.playerState.maxPitch);
  }

  public addYaw(radians) {
    this._yaw += radians;
    this._yaw = this._yaw % (Math.PI * 2);
  }

  public move() {
    let _this = this;

    return {
      forward: function () {
        _this.targetSpeed = _this.playerState.targetSpeed(_this._gradient);
        _this.playerState.resolveMove(_this.physicsBody, _this.forward, _this.targetSpeed);
      },
      backward: function () {
        _this.targetSpeed = _this.playerState.targetSpeed(_this._gradient);
        _this.playerState.resolveMove(_this.physicsBody, _this.backward, _this.targetSpeed);
      },
      left: function () {
        _this.targetSpeed = _this.playerState.targetSpeed(_this._gradient);
        _this.playerState.resolveMove(_this.physicsBody, _this.left, _this.targetSpeed);
      },
      right: function () {
        _this.targetSpeed = _this.playerState.targetSpeed(_this._gradient);
        _this.playerState.resolveMove(_this.physicsBody, _this.right, _this.targetSpeed);
      },
    };
  }

  public revertPlayerState() {
    this.playerState.set(this._prevPlayerState);
  }

  public setFromPitchYawRoll(gameTime) {
    this._qPitch.setFromAxisAngle(this.right, this._pitch);
    this._qYaw.setFromAxisAngle(DEFAULT_VECTORS.up, this._yaw);
    this._qRoll.setFromAxisAngle(DEFAULT_VECTORS.forward, this._roll);
    let qTarget = new Quaternion().multiplyQuaternions(this._qPitch, this._qYaw).normalize();
    qTarget.multiply(this._qRoll);
    this.physicsBody.setOrientation(this.physicsBody.orientation.clone().slerp(qTarget, gameTime.deltaTime / 0.075));
  }

  public update(gameTime: GameTime, physicsBody: PhysicsBody) {
    this.setFromPitchYawRoll(gameTime);

    this._t += gameTime.deltaTime * 0.0038;
    let w = this._wayPointPath.at(this._t);

    if (w) {
      if (this._wayPointPath.next !== this._lastWayPoint) {
        // debug().addDebugWayPoint(this._wayPointPath.next);
        this._lastWayPoint = this._wayPointPath.next;
      }

      physicsBody.setPosition(w.position);
      this._pitch = w.direction.y;

      if (w.userData && w.userData.opacity !== undefined) {
        this.canvas.style.opacity = MathUtils.clamp(w.userData.opacity, 0, 1).toFixed(3);
        this.coverText.style.opacity = (0.9 - MathUtils.clamp(w.userData.opacity, 0, 1)).toFixed(3);
      }
    }
  }

  // #endregion Public Methods (6)
}

export default DollyCamera;
