import { Vector3 } from "three";
import GameTime from "../../GameTime";
import BoxCollider from "../Colliders/BoxCollider";
import PhysicsBody from "../PhysicsBody";
import Velocity from "../Velocity";

/**
 * Resolves a collision with a box collider for a single PhysicsBody
 * @param physicsBody the PhysicsBody to resolve
 * @param collisionNormal the normal vector of the collision surface
 * @param collider the specific collider of the PhysicsBody that requires resolving
 */
export default function PhysicsBodyBoxResolver(
  physicsBody: PhysicsBody,
  poi: Vector3,
  collisionNormal: Vector3,
  collider: BoxCollider,
  gameTime: GameTime
) {
  if (physicsBody.isStatic) {
    return;
  }

  let reflect = physicsBody.velocity.direction.clone().reflect(collisionNormal);
  physicsBody.move(new Velocity(reflect, physicsBody.velocity.speed * physicsBody.bounciness));
}
