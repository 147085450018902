import {
  Camera,
  Color,
  FileLoader,
  FrontSide,
  LoadingManager,
  Mesh,
  MeshStandardMaterial,
  NearestFilter,
  PlaneBufferGeometry,
  Scene,
  Texture,
  TextureLoader,
  Vector2,
  Vector3,
  WebGLRenderer
} from "three";
import GameTime from "./GameTime";
import TerrainMaterial from "./TerrainMaterial";

export interface ITerrainParams {
  // #region Properties (5)

  mapSize?: number;
  maxHeight?: number;
  ocean?: IOceanParams;
  subdivisionSize?: number;
  viewDistance?: number;

  // #endregion Properties (5)
}

export interface IOceanParams {
  // #region Properties (2)

  color: any;
  height: number;

  // #endregion Properties (2)
}

interface IShaderUniform {
  // #region Properties (2)

  type?: string;
  value?: any;

  // #endregion Properties (2)
}

interface IOceanShaderUniforms {
  // #region Properties (10)

  heightMap: IShaderUniform;
  heightScale: IShaderUniform;
  mapSize: IShaderUniform;
  nSeaLevel: IShaderUniform;
  playerPos: IShaderUniform;
  subdivisions: IShaderUniform;
  time: IShaderUniform;
  viewSize: IShaderUniform;
  waveFreq: IShaderUniform;
  waveOrigin: IShaderUniform;

  // #endregion Properties (10)
}

export default class Terrain {
  // #region Properties (14)

  public cameraPos: Vector3;
  public mapSize: number;
  public maxHeight: number;
  public ocean: IOceanParams;
  public oceanGeom: PlaneBufferGeometry;
  public oceanMaterial: any;
  //MeshStandardMaterial;
  public oceanMesh: Mesh;
  public oceanUniforms: IOceanShaderUniforms;
  public parentScene: Scene;
  public renderer: WebGLRenderer;
  public subdivisions: number;
  public terrainMesh: Mesh;
  public viewDistance: number;
  public viewSize: number;

  // #endregion Properties (14)

  // #region Constructors (1)

  constructor(renderer: WebGLRenderer, scene: Scene, args: ITerrainParams) {
    this.terrainMesh;
    this.oceanMesh;
    this.renderer = renderer;
    this.parentScene = scene;
    this.cameraPos = new Vector3();
    this.mapSize = args.mapSize || 4096;
    this.viewSize = args.viewDistance;
    this.subdivisions = this.viewSize / args.subdivisionSize;
    this.maxHeight = args.maxHeight || 100;
    this.ocean = args.ocean || {
      height: args.ocean ? (args.ocean.height ? args.ocean.height : 0) : 0,
      color: args.ocean ? (args.ocean.color ? args.ocean.color : "#11378A") : "#11378A",
    };
  }

  // #endregion Constructors (1)

  // #region Public Methods (5)

  private addToScene(scene: Scene) {
    scene.add(this.terrainMesh);
    if (this.oceanMesh) scene.add(this.oceanMesh);
  }

  public async loadAsync(heightMapPath: string) {
    console.log("loading terrain");

    return new Promise((resolve, reject) => {
      let terrainVertexShader,
        oceanVertexShader,
        heightMap: Texture,
        terrainGeom: PlaneBufferGeometry,
        _this = this;

      let loadingManager = new LoadingManager(function () {
        heightMap.minFilter = NearestFilter;
        heightMap.magFilter = NearestFilter;

        let terrainMaterial = new TerrainMaterial({
          color: new Color(0xd1f4ff),
          heightMap: heightMap,
          heightScale: _this.maxHeight,
          mapSize: _this.mapSize,
          playerPos: _this.cameraPos,
          subdivisions: _this.subdivisions,
          textureSize: heightMap.image.width,
          viewSize: _this.viewSize,
        });

        terrainGeom = new PlaneBufferGeometry(_this.viewSize, _this.viewSize, _this.subdivisions, _this.subdivisions);
        terrainGeom.computeBoundingBox();
        terrainGeom.computeBoundingSphere();
        terrainGeom.boundingSphere.radius = Math.max(_this.viewSize, _this.maxHeight);
        _this.terrainMesh = new Mesh(terrainGeom, terrainMaterial);
        _this.terrainMesh.position.y = 0;
        _this.terrainMesh.rotation.x = -Math.PI / 2;
        _this.terrainMesh.receiveShadow = true;
        _this.terrainMesh.castShadow = true;
        _this.terrainMesh.visible = true;

        if (_this.ocean) {
          _this.oceanGeom = new PlaneBufferGeometry(
            _this.viewSize,
            _this.viewSize,
            _this.subdivisions,
            _this.subdivisions
          );
          _this.oceanMaterial = new MeshStandardMaterial({
            color: _this.ocean.color,
            flatShading: true,
            roughness: 0.6,
            metalness: 0.4,
            side: FrontSide,
          });

          _this.oceanUniforms = {
            heightMap: {
              value: heightMap,
            },
            heightScale: {
              value: 2,
            },
            nSeaLevel: {
              value: _this.ocean.height / _this.maxHeight,
            },
            playerPos: {
              value: _this.cameraPos,
            },
            mapSize: {
              value: _this.mapSize,
            },
            viewSize: {
              value: _this.viewSize,
            },
            subdivisions: {
              value: _this.subdivisions,
            },
            time: {
              value: 0,
            },
            waveFreq: {
              value: 1300,
            },
            waveOrigin: {
              type: "v2v",
              value: [new Vector2(0.5, 0.5), new Vector2(0.3, 0.25), new Vector2(0.9, 0.75)],
            },
          };

          _this.oceanMaterial.onBeforeCompile = function (shader) {
            for (let p in _this.oceanUniforms) {
              shader.uniforms[p] = _this.oceanUniforms[p];
            }
            shader.vertexShader = oceanVertexShader;
          };
          _this.oceanMesh = new Mesh(_this.oceanGeom, _this.oceanMaterial);
          _this.oceanMesh.position.set(0, 0, 0);
          _this.oceanMesh.rotation.x = -Math.PI / 2;
          _this.oceanMesh.visible = true;
          _this.oceanMesh.castShadow = false;
          _this.oceanMesh.receiveShadow = false;
        }

        _this.addToScene(_this.parentScene);

        console.log("terrain loaded");
        resolve(_this);
      });

      let textureLoader = new TextureLoader(loadingManager);
      heightMap = textureLoader.load(heightMapPath);

      let fileLoader = new FileLoader(loadingManager);
      fileLoader.load("/shaders/OceanVertexShader.glsl", function (data) {
        oceanVertexShader = data;
      });
    });
  }

  public oceanHeightAt(x: number, z: number) {
    let displace = 0;
    let waveFreq = this.oceanUniforms.waveFreq.value;
    let time = this.oceanUniforms.time.value;
    // let tDepth = ( this.ocean.height - this.heightAt( x, z ).h ) / this.maxHeight || 1;
    let tDepth = 1;

    // Convert player position to the equivalent UV coord.
    let uv = new Vector2(x, z);
    uv.addScalar(this.mapSize / 2);
    uv.divideScalar(this.mapSize);

    for (let i = 0; i < 3; i++) {
      let waveOriginDist = this.oceanUniforms.waveOrigin.value[i].distanceTo(uv);
      displace += Math.cos(waveFreq * (1 / (i + 1)) * waveOriginDist + time * 2) * tDepth;
      displace += Math.cos(waveFreq * tDepth * 2.5 * waveOriginDist - time * 1.5) * 0.75;
    }
    return this.ocean.height + displace * tDepth * this.oceanUniforms.heightScale.value;
  }

  public removeFromScene(scene: Scene) {
    scene.remove(this.terrainMesh);
    if (this.oceanMesh) scene.remove(this.oceanMesh);
  }

  public update(camera: Camera, gameTime: GameTime) {
    this.oceanUniforms.time.value = gameTime.elapsedTime * 0.8;

    if (this.terrainMesh) {
      this.cameraPos.set(camera.position.x, camera.position.y, camera.position.z);
      this.terrainMesh.position.set(camera.position.x, 0, camera.position.z);
    }
    if (this.oceanMesh) {
      this.oceanMesh.position.set(camera.position.x, this.ocean.height, camera.position.z);
    }
  }

  // #endregion Public Methods (5)
}
