export interface IPlayerControllerParams {
  useMouseLook: boolean;
  enabled: boolean;
  keyMap: any;
  actions: any;
}

export default class PlayerController {
  // #region Properties (5)

  public actions: any;
  public enabled: boolean;
  public keyMap: any;
  public keyStates: {};
  public useMouseLook: any;

  // #endregion Properties (5)

  // #region Constructors (1)

  constructor(args: IPlayerControllerParams) {
    let _this = this;
    this.useMouseLook = args.useMouseLook || false;
    this.enabled = args.enabled === true ? true : false;

    this.keyMap = args.keyMap || {
      turnright: "39",
      turnleft: "37",
      forward: "87",
      backward: "83",
      up: "38",
      down: "40",
      strafeleft: "65",
      straferight: "68",
    };

    this.actions = args.actions || {
      turnright: function () {},
      turnleft: function () {},
      forward: function () {},
      backward: function () {},
      up: function () {},
      down: function () {},
      strafeleft: function () {},
      straferight: function () {},
      mousemove: function () {},
    };

    this.keyStates = {};
    for (let k in this.keyMap) {
      this.keyStates[k] = {
        active: false,
        changed: false,
      };
    }

    var onKeyDown = function (ev: KeyboardEvent) {
      if (!_this.enabled) return;

      // Convert keyCode to movement identifier
      for (let k in _this.keyMap) {
        if (ev.keyCode == _this.keyMap[k]) {
          let lastState = _this.keyStates[k].active;
          _this.keyStates[k] = {
            active: true,
            changed: lastState == true ? false : true,
          };
        }
      }

      ev.preventDefault();
    };

    var onKeyUp = function (ev: KeyboardEvent) {
      if (!_this.enabled) return;

      for (let k in _this.keyMap) {
        if (ev.keyCode == _this.keyMap[k]) {
          let lastState = _this.keyStates[k].active;
          _this.keyStates[k] = {
            active: false,
            changed: lastState == true ? true : false,
          };
        }
      }
    };

    var onMouseMove = function (ev: KeyboardEvent) {
      if (!_this.enabled) return;

      if (typeof _this.actions.mousemove == "function" && _this.useMouseLook) _this.actions.mousemove(_this, ev);
    };

    // Register events
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    window.addEventListener("mousemove", onMouseMove);
  }

  // #endregion Constructors (1)

  // #region Public Methods (1)

  public update() {
    if (this === undefined || !this.enabled) return;

    for (let k in this.keyStates) {
      if (typeof this.actions[k] !== "function") continue;

      if (this.keyStates[k].active) {
        this.actions[k](this, false);
      } else if (!this.keyStates[k].active && this.keyStates[k].changed) {
        this.actions[k](this, true);
      }
      this.keyStates[k].changed = false;
    }
  }

  // #endregion Public Methods (1)
}