import { Vector3 } from "three";
import DEFAULT_VECTORS from "../DEFAULT_VECTORS";
import GameTime from "../GameTime";
import BroadPhase from "./BroadPhase";
import Force from "./Force";
import NarrowPhase from "./NarrowPhase";
import PhysicsBody from "./PhysicsBody";
import TerrainPhysicsBody from "./TerrainPhysicsBody";

export default class PhysicsScene {
  // #region Properties (5)

  private _entitySize: Vector3;
  private _sceneEntities: Array<PhysicsBody>;
  private _terrain: TerrainPhysicsBody;

  public static maxIterationsPerPass = 10;

  public gAcceleration: number;

  // #endregion Properties (5)

  // #region Constructors (1)

  constructor() {
    this._sceneEntities = Array();
    this._entitySize = new Vector3();
    this.gAcceleration = 30;
  }

  // #endregion Constructors (1)

  // #region Public Accessors (2)

  public get entities() {
    return this._sceneEntities;
  }

  public get terrain() {
    return this._terrain;
  }

  // #endregion Public Accessors (2)

  // #region Public Methods (3)

  public add(physicsBody: PhysicsBody) {
    if (physicsBody instanceof TerrainPhysicsBody) {
      this._terrain = physicsBody;
    } else {
      this._sceneEntities.push(physicsBody);
    }
  }

  public remove(physicsBody: PhysicsBody) {
    this._sceneEntities = this._sceneEntities.filter((x) => x !== physicsBody);
  }

  public update(gameTime: GameTime) {
    if (this._sceneEntities.length > 1) {
      let collisionsOccurred = true;
      let iteration = 0;

      while (collisionsOccurred && iteration < PhysicsScene.maxIterationsPerPass) {
        iteration++;
        let broadPhaseResult = BroadPhase(this._sceneEntities);
        collisionsOccurred = NarrowPhase(broadPhaseResult, gameTime);
      }
    }

    // Terrain gravity handling is a different beast...
    for (let i = 0; i < this._sceneEntities.length; i++) {
      let entity = this._sceneEntities[i];

      if (this._terrain && entity.followTerrain) {
        let targetPos = entity.position.clone();
        let terrainHeight = this._terrain.heightAt(entity.position.x, entity.position.z);

        entity.bounds.getSize(this._entitySize);
        this._entitySize.multiply(entity.scale);
        targetPos.y = (terrainHeight ? terrainHeight.h : targetPos.y) + this._entitySize.y;

        if (entity.position.y <= targetPos.y) {
          entity.setPosition(targetPos);
          // entity.position.y = targetPos.y;
          entity.resetForces();
        } else {
          // Apply gravity
          entity.applyForce(new Force(DEFAULT_VECTORS.down, this.gAcceleration));
        }
      }

      entity.update(gameTime);
      entity.resetMoves();
      entity.resetTurns();
    }
  }

  // #endregion Public Methods (3)
}
