import { Matrix4, Quaternion, Vector3 } from "three";
import DEFAULT_VECTORS from "../DEFAULT_VECTORS";
import GameTime from "../GameTime";
import Bounds from "./Bounds";
import ICollider from "./Colliders/ICollider";
import Force from "./Force";
import Velocity from "./Velocity";

type OnUpdatePhysicsBody = (gameTime: GameTime, physicsBody: PhysicsBody) => void;

export default class PhysicsBody {
  // #region Properties (22)

  private _bounds: Bounds;
  private _colliders: Array<ICollider>;
  private _currentVelocity: Velocity;
  private _forceBuffer: Array<Force>;
  private _matrixWorld: Matrix4;
  private _moveBuffer: Array<Velocity>;
  private _orientation: Quaternion;
  private _position: Vector3;
  private _scale: Vector3;
  private _targetForce: Force;
  private _targetVelocity: Velocity;
  private _tempMatrix: Matrix4;
  private _turnBuffer: Array<Quaternion>;
  private _worldBounds: Bounds;

  public static EPSILON = 0.001;
  public static onUpdate: OnUpdatePhysicsBody;

  public acceleration: number;
  public bounciness: number;
  public followTerrain: boolean;
  public isStatic: boolean;
  public onPreUpdate: OnUpdatePhysicsBody;
  public onPostUpdate: OnUpdatePhysicsBody;
  public userData: any;

  // #endregion Properties (22)

  // #region Constructors (1)

  constructor(colliders?: Array<ICollider>) {
    this.acceleration = 1;
    this.followTerrain = false;
    this.isStatic = false;
    this.bounciness = 0.1;

    this._colliders = colliders || [];
    this._bounds = new Bounds(this._colliders);
    this._worldBounds = this._bounds.clone();
    this._forceBuffer = Array();
    this._moveBuffer = Array();
    this._turnBuffer = Array();
    this._currentVelocity = new Velocity(new Vector3(), 0);
    this._targetVelocity = new Velocity(new Vector3(), 0);
    this._targetForce = new Force(new Vector3(), 0);
    this._colliders = [];
    this._matrixWorld = new Matrix4();
    this._position = new Vector3();
    this._orientation = new Quaternion();
    this._scale = new Vector3(1, 1, 1);
    this._tempMatrix = new Matrix4();
  }

  // #endregion Constructors (1)

  // #region Public Accessors (11)

  public get bounds(): Bounds {
    return this._bounds;
  }

  public get colliders(): ICollider[] {
    return this._colliders;
  }

  public get forward(): Vector3 {
    let r = this.resolveTurns();
    let q = new Quaternion();
    q.copy(this.orientation);
    q.multiply(r); // This gives us the new rotation of the object in 'q'
    return DEFAULT_VECTORS.forward.applyQuaternion(q).normalize();
  }

  public get matrixWorld(): Matrix4 {
    return this._matrixWorld;
  }

  public get numMoves(): number {
    return this._moveBuffer.length;
  }

  public get orientation(): Quaternion {
    // return this._orientation.setFromRotationMatrix(this._matrixWorld).normalize();
    this._matrixWorld.decompose(this._position, this._orientation, this._scale);
    return this._orientation;
  }

  public get position(): Vector3 {
    return this._position.setFromMatrixPosition(this._matrixWorld);
  }

  public get right(): Vector3 {
    return new Vector3().crossVectors(this.forward, DEFAULT_VECTORS.up).normalize();
  }

  public get scale(): Vector3 {
    this._matrixWorld.decompose(this._position, this._orientation, this._scale);
    return this._scale;
  }

  public get velocity(): Velocity {
    return this._currentVelocity;
  }

  public get worldBounds(): Bounds {
    return this._worldBounds.copy(this._bounds).applyMatrix4(this._matrixWorld);
  }

  // #endregion Public Accessors (11)

  // #region Public Methods (21)

  public addCollider(collider: ICollider) {
    this._colliders.push(collider);

    // Recalculate bounds
    this._bounds = new Bounds(this._colliders);
  }

  public applyForce(f: Force) {
    if (f instanceof Force && !this.isStatic) {
      this._forceBuffer.push(f);
    }
    return this;
  }

  /** Adds the given position vector to the current position
   * @param vec The vector to apply to the current position
   */
  public applyPosition(vec: Vector3) {
    this._matrixWorld.setPosition(this.position.clone().add(vec));
  }

  public getColliderWorldMatrix(collider: ICollider): Matrix4 {
    this._tempMatrix.multiplyMatrices(this.matrixWorld, collider.matrix);
    return this._tempMatrix;
  }

  public move(v: Velocity) {
    if (v instanceof Velocity && !this.isStatic) {
      this._moveBuffer.push(v);
    }
    return this; // so we can chain moves/turns
  }

  public resetForces() {
    delete this._forceBuffer;
    this._forceBuffer = Array();
  }

  public resetMoves() {
    delete this._moveBuffer;
    this._moveBuffer = Array();
  }

  public resetTurns() {
    delete this._turnBuffer;
    this._turnBuffer = Array();
  }

  public resetVelocity() {
    this._currentVelocity = new Velocity();
    this._targetVelocity = new Velocity();
  }

  public resolveForces() {
    let result = new Force();
    for (let i = 0; i < this._forceBuffer.length; i++) {
      result = result.add(this._forceBuffer[i]);
    }

    delete this._forceBuffer;
    this._forceBuffer = [result];
    return result;
  }

  public resolveMoves() {
    let result = new Velocity();
    for (let i = 0; i < this._moveBuffer.length; i++) {
      result = result.add(this._moveBuffer[i]);
    }
    return result;
  }

  public resolveTurns() {
    let result = new Quaternion();
    for (let i = 0; i < this._turnBuffer.length; i++) {
      result.multiply(this._turnBuffer[i]);
    }
    return result.normalize();
  }

  public resolveVelocity(deltaTime: number) {
    // Calculate current velocity from target speed and acceleration
    let targetVelocity = this.resolveMoves();

    // Resolve any forces applied
    let targetForce = this.resolveForces();
    return targetVelocity.add(new Velocity(targetForce.direction, targetForce.acceleration * deltaTime));
  }

  public setOrientation(q: Quaternion) {
    this._matrixWorld.decompose(this._position, this._orientation, this._scale);
    this._matrixWorld.compose(this._position, q, this._scale);
  }

  public setPosition(x: number | Vector3, y?: number, z?: number) {
    if (x instanceof Vector3) {
      this._matrixWorld.setPosition(x);
    } else {
      this._matrixWorld.setPosition(x, y, z);
    }
  }

  public setScale(linearScale: number) {
    let newScale = new Vector3(linearScale, linearScale, linearScale);
    this._matrixWorld.decompose(this._position, this._orientation, this._scale);
    this._matrixWorld.compose(this._position, this._orientation, newScale);
  }

  public turn(q: Quaternion) {
    if (typeof q === "object" && q.isQuaternion && !this.isStatic) {
      this._turnBuffer.push(q);
    }
    return this; // so we can chain moves/turns
  }

  public undoForces(n: number) {
    if (n > 0) {
      this._forceBuffer = this._forceBuffer.slice(0, -Math.min(n, this._forceBuffer.length));
    }
  }

  public undoMoves(n: number) {
    if (n > 0) {
      this._moveBuffer = this._moveBuffer.slice(0, -Math.min(n, this._moveBuffer.length));
    }
  }

  public undoTurns(n: number) {
    if (n > 0) {
      this._turnBuffer = this._turnBuffer.slice(0, -Math.min(n, this._turnBuffer.length));
    }
  }

  public update(gameTime: GameTime) {
    if (this.onPreUpdate) {
      this.onPreUpdate(gameTime, this);
    }

    this._targetVelocity = this.resolveVelocity(gameTime.deltaTime);

    let velocityDiff = this._targetVelocity.sub(this._currentVelocity);

    if (Math.abs(velocityDiff.speed) > PhysicsBody.EPSILON) {
      this._currentVelocity = this._currentVelocity.accelerateTo(
        this._targetVelocity,
        gameTime.deltaTime * this.acceleration
      );
    } else {
      // Eliminate really small differences in velocity
      this._currentVelocity.copy(this._targetVelocity);
    }

    this.setPosition(this.position.clone().add(this._currentVelocity.resolve(gameTime.deltaTime)));
    this.setOrientation(this.orientation.clone().multiply(this.resolveTurns()));

    if (this.onPostUpdate) {
      this.onPostUpdate(gameTime, this);
    } else if (PhysicsBody.onUpdate) {
      PhysicsBody.onUpdate(gameTime, this);
    }
  }

  // #endregion Public Methods (21)
}
