import "babel-polyfill";
import "./common";
import Game from "./Game";

document.addEventListener("DOMContentLoaded", () => {
  Game(document.getElementById("container")).then((args) => {
    (window as any).game = { ...args };
    document.getElementById("container").style.display = "block";
    let coverText = document.getElementsByClassName("cover-text")[0] as HTMLElement;
    let loader = document.getElementsByClassName("loader")[0] as HTMLElement;
    if (coverText) {
      coverText.style.display = "block";
    }

    if (loader) {
      loader.remove();
    }
  });
});
