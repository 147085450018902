import { DirectionalLight, Plane, Triangle, Vector3 } from "three";

Plane.prototype.intersectRay = function(p, r, target) {
  let nr = r.normalize();
  let t = (this.constant - this.normal.dot(p)) / this.normal.dot(nr);

  if (isFinite(t) && t >= 0) {
    return target.copy(p.add(nr.multiplyScalar(t)));
  }
  return false;
};

Triangle.prototype.intersectRay = function(p, ray, result) {
  let ab = new Vector3().subVectors(this.b, this.a);
  let ac = new Vector3().subVectors(this.c, this.a);

  let n = new Vector3().crossVectors(ab, ac);
  let d = ray.dot(n);
  if (d <= 0) return false;

  let ap = new Vector3().subVectors(p, this.a);
  let t = -ap.dot(n);
  if (t < 0) return false;

  let e = new Vector3().crossVectors(ray, ap);
  let u, v, w;
  v = ac.dot(e);
  if (v < 0 || v > d) return false;

  w = -ab.dot(e);
  if (w < 0 || v + w > d) return false;

  let ood = 1.0 / d;
  t *= ood;
  v *= ood;
  w *= ood;
  u = 1.0 - v - w;
  let pRay = ray.multiplyScalar(t);
  result.point = pRay.add(p);
  result.normal = new Vector3(u, v, w);
  return true;
};

DirectionalLight.prototype.targetOffset = new Vector3(200, 300, 100);
DirectionalLight.prototype.update = function() {
  this.position.set(
    this.target.position.x + this.targetOffset.x,
    this.target.position.y + this.targetOffset.y,
    this.target.position.z + this.targetOffset.z
  );
};

Object.completeAssign = function(target, ...sources) {
  sources.forEach(source => {
    let descriptors = Object.keys(source).reduce((descriptors, key) => {
      descriptors[key] = Object.getOwnPropertyDescriptor(source, key);
      return descriptors;
    }, {});
    // by default, Object.assign copies enumerable Symbols too
    Object.getOwnPropertySymbols(source).forEach(sym => {
      let descriptor = Object.getOwnPropertyDescriptor(source, sym);
      if (descriptor.enumerable) {
        descriptors[sym] = descriptor;
      }
    });
    Object.defineProperties(target, descriptors);
  });
  return target;
};
